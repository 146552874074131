/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import Sidebar from "../../../../components/Sidebar";
import DetailsMenu from "../../../../components/Details/components/Header/DetailsTabs";
import LeadSummary from "../sections/Summary";
import LeadMeetings from "../sections/LeadMeetings";
import useLeadDetails from "../../../../hooks/useLeadDetails";
import useLeadColor from "../../../../hooks/useLeadColor";
import { LeadStatus } from "../../../../graphql/generated/types";

const LeadFlow: FC = () => {
  const {
    leadData: { type, status },
  } = useLeadDetails();
  const color = useLeadColor(type!);

  const leadMenu = useMemo(() => {
    return [
      {
        title: "Geral",
        Component: LeadSummary,
        color: color,
      },
      {
        title: "Conversas",
        Component: LeadMeetings,
        color: color,
        disabled: status === LeadStatus.Refused,
      },
    ];
  }, [color, status]);

  return <Sidebar TopComponent={DetailsMenu} menuItems={leadMenu} />;
};

export default LeadFlow;
