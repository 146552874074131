import { FC, useCallback, useMemo } from "react";
import {
  ClaimStatus,
  ClaimEvent,
  LossEstimate,
  Claim,
  GetEditClaimOnFormResponseDocument,
  Services,
  GetEditConclusionLetterOnClaimDocument,
  GetEditEstimatedIndemnityOnClaimDocument,
  GetEditContestationLetterOnClaimDocument,
  useSetIndemnityPaidMutation,
  GetEditHarvestDatesLettersOnClaimFormResponseDocument,
  GetEditClaimOnFormResponseQueryVariables,
} from "../../../../../../../graphql/generated/types";
import { formatPrice } from "../../../../../../../utils/formatNumber";
import { concatArrayOfStrings } from "../../../../../../../utils/formatString";
import EditableSection from "../../../../../../../components/EditableSection";
import DetailsSectionColumns from "../../../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import useDocumentDetails from "../../../../../../../hooks/useDocumentDetails";
import { useModalStore } from "../../../../../../../components/Modal/context/ModalStore";
import { DetailsRowValueVariants } from "../../../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import { DetailsRowProps } from "../../../../../../../components/Details/components/Main/Grid/DetailsRow";
import { IconNames } from "../../../../../../../components/Icons/styles/iconNames";
import { useErrorHandler } from "../../../../../../../hooks/useErrorHandler";
import { formatISODate } from "../../../../../../../utils/dateUtils";

export type MappedClaim = Claim & { eventsLabel: string | string[] };

interface ClaimProps {
  data: MappedClaim;
}

export const ClaimStatusLabels = {
  [ClaimStatus.Pending]: "Pendente",
  [ClaimStatus.Open]: "Aberto",
  [ClaimStatus.Approved]: "Deferido",
  [ClaimStatus.Rejected]: "Indeferido",
  [ClaimStatus.Contested]: "Em Contestação",
  [ClaimStatus.Paid]: "Pago",
};

export const ClaimEventLabels = {
  [ClaimEvent.Fire]: "Incêndio",
  [ClaimEvent.Drought]: "Seca",
  [ClaimEvent.Rain]: "Chuva excessiva",
  [ClaimEvent.Lightning]: "Raio",
  [ClaimEvent.Hail]: "Granizo",
  [ClaimEvent.Wind]: "Vendaval",
  [ClaimEvent.Frost]: "Geada",
  [ClaimEvent.Waterspout]: "Tromba d'água",
  [ClaimEvent.ColdWinds]: "Ventos frios",
  [ClaimEvent.Inundation]: "Inundação",
  [ClaimEvent.TemperatureVariation]: "Variação excessiva de temperatura",
};

const LossEstimateLabels = {
  [LossEstimate.Low]: "Baixa",
  [LossEstimate.Medium]: "Média",
  [LossEstimate.High]: "Severa",
};

const ClaimComponent: FC<ClaimProps> = ({ data: claim }) => {
  const { refetch } = useDocumentDetails();

  const { closeModal } = useModalStore();
  const [setPaidMutation] = useSetIndemnityPaidMutation();
  const { errorHandler } = useErrorHandler();
  const handleSetIndemnityPaid = useCallback(async () => {
    try {
      await setPaidMutation({
        variables: {
          objectId: claim?._id,
        },
      });
      refetch();
      closeModal();
    } catch (e) {
      errorHandler(
        new Error("Não foi possível registrar o pagamento dessa indenização"),
        e
      );
    }
  }, []);

  const claimInfo = useMemo(() => {
    return [
      [
        {
          label: "ID na Seguradora",
          value: claim.externalId,
        },
        {
          label: "Nº de Protocolo de Ligação",
          value: claim.protocolNumber,
        },
        {
          label: "Status",
          value: ClaimStatusLabels[claim.status],
        },
        {
          label: "Data de criação",
          value: formatISODate(claim.createdAt),
        },
        {
          label: "Data de aviso na Seguradora",
          value: formatISODate(claim.noticeDate),
        },
        {
          label: "Início do evento",
          value: formatISODate(claim.startDate),
        },
        {
          label: "Final do evento",
          value: formatISODate(claim.endDate),
        },
        {
          label: "Carta de conclusão",
          variant: DetailsRowValueVariants.File,
          value: {
            file: {
              fileName: claim.reportFile?.fileName,
            },
            objectId: claim.reportFile && claim._id,
            fieldPath: "reportFile",
            serviceName: Services.Claims,
          },
        },
        {
          label: "Carta de contestação",
          variant: DetailsRowValueVariants.File,
          value: {
            file: {
              fileName: claim.contestationLetterFile?.fileName,
            },
            objectId: claim.contestationLetterFile && claim._id,
            fieldPath: "contestationLetterFile",
            serviceName: Services.Claims,
          },
        },
      ] as DetailsRowProps[],
      [
        {
          label: "Eventos",
          value: claim.eventsLabel,
        },
        {
          label: "Perda estimada",
          value: LossEstimateLabels[claim.lossEstimate],
        },
        {
          label: "Área afetada",
          value: claim.plots?.length
            ? concatArrayOfStrings(claim.plots.map((plot) => plot))
            : "Total",
        },
        {
          label: "Coberturas",
          value:
            claim.coverages &&
            concatArrayOfStrings(claim.coverages.map((coverage) => coverage)),
        },
        {
          label: "Observações",
          value: claim.comment,
        },
        {
          label: "Estimativa de indenização",
          value:
            claim.estimatedIndemnity && formatPrice(claim.estimatedIndemnity),
        },
        {
          label: "Data de indenização",
          value:
            claim.indemnityPaymentDate &&
            formatISODate(claim.indemnityPaymentDate),
        },
        {
          label: "Valor de indenização",
          value: claim.indemnityAmount && formatPrice(claim.indemnityAmount),
        },
      ],
    ];
  }, [claim]);

  const harvestDatesLetters = useMemo(() => {
    return [
      [
        {
          label: "Carta de início de colheita",
          variant: DetailsRowValueVariants.File,
          value: {
            file: {
              fileName: claim.harvestStartDateLetterFile?.fileName,
            },
            objectId: claim.harvestStartDateLetterFile && claim._id,
            fieldPath: "harvestStartDateLetterFile",
            serviceName: Services.Claims,
          },
        },
        {
          label: "Carta de fim de colheita",
          variant: DetailsRowValueVariants.File,
          value: {
            file: {
              fileName: claim.harvestEndDateLetterFile?.fileName,
            },
            objectId: claim.harvestEndDateLetterFile && claim._id,
            fieldPath: "harvestEndDateLetterFile",
            serviceName: Services.Claims,
          },
        },
      ],
      [
        {
          label: "Carta de início de colheita assinada",
          variant: DetailsRowValueVariants.File,
          value: {
            file: {
              fileName: claim.harvestStartDateSignedLetterFile?.fileName,
            },
            objectId: claim.harvestStartDateSignedLetterFile && claim._id,
            fieldPath: "harvestStartDateSignedLetterFile",
            serviceName: Services.Claims,
          },
        },
        {
          label: "Carta de fim de colheita assinada",
          variant: DetailsRowValueVariants.File,
          value: {
            file: {
              fileName: claim.harvestEndDateSignedLetterFile?.fileName,
            },
            objectId: claim.harvestEndDateSignedLetterFile && claim._id,
            fieldPath: "harvestEndDateSignedLetterFile",
            serviceName: Services.Claims,
          },
        },
      ],
    ] as DetailsRowProps[][];
  }, [claim]);

  const footerButtons = useMemo(() => {
    return [
      claim?.status === ClaimStatus.Open && {
        text: "Adicionar Carta de Conclusão",
        formQuery: {
          query: GetEditConclusionLetterOnClaimDocument,
          variables: {
            objectId: claim?._id,
          },
        },
        iconName: IconNames.Add,
      },
      claim?.status === ClaimStatus.Open &&
        !claim.estimatedIndemnity && {
          text: "Adicionar estimativa de indenização",
          formQuery: {
            query: GetEditEstimatedIndemnityOnClaimDocument,
            variables: {
              objectId: claim?._id,
            },
          },
          iconName: IconNames.Add,
        },
      claim?.status === ClaimStatus.Open && {
        text: "Adicionar cartas de início e fim de colheita",
        formQuery: {
          query: GetEditHarvestDatesLettersOnClaimFormResponseDocument,
          variables: {
            objectId: claim?._id,
          },
        },
        useV2: true,
        iconName: IconNames.Add,
      },
      claim.status === ClaimStatus.Rejected && {
        text: "Adicionar Carta de Contestação",
        formQuery: {
          query: GetEditContestationLetterOnClaimDocument,
          variables: {
            objectId: claim?._id,
          },
        },
        iconName: IconNames.Add,
      },
      claim?.status === ClaimStatus.Approved && {
        text: "Marcar indenização como paga",
        onClick: handleSetIndemnityPaid,
        iconName: IconNames.Add,
      },
    ].filter(Boolean) as any;
  }, []);

  return (
    <EditableSection<{}, GetEditClaimOnFormResponseQueryVariables>
      title={claim.name}
      editQuery={GetEditClaimOnFormResponseDocument}
      objectId={claim._id}
      main
      onSubmit={() => {
        refetch(), closeModal();
      }}
      footerButtons={footerButtons}
    >
      <DetailsSectionColumns columns={claimInfo} />
      <DetailsSectionColumns columns={harvestDatesLetters} />
    </EditableSection>
  );
};

export default ClaimComponent;
