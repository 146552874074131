import userFilters from "../filters/users";
import useModelFilter from "./useModelFilter";
import useUrlFilter from "./useUrlFilter";
import { CountUsersDocument } from "../graphql/generated/types";
import useModel from "./useModel";
import { UserObject } from "../models/User";

const useUserFilter = () => {
  const usersModel = useModel<UserObject>("users");
  const filters = useModelFilter(
    userFilters,
    [
      async () =>
        usersModel.search({ role: "admin" }).then((res) => [
          "responsible",
          res.map((partner) => ({
            value: partner._id,
            label: partner.name!,
            filter: { responsibleId: partner._id },
          })),
        ]),
    ],
    CountUsersDocument
  );

  useUrlFilter(filters);

  return filters;
};

export default useUserFilter;
