/** @jsxImportSource @emotion/react */
import { GetLeadDetailsDocument } from "../../../graphql/generated/types";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FC, useMemo } from "react";
import { leadDetailsTabRoutes } from "../../../Routes";
import DetailsWrapper from "../../../components/Details/components/Main/DetailsWrapper";
import SkeletonDetails from "../../../components/Details/components/SkeletonDetails";
import LeadDetailsProvider from "../../../contexts/leads";
import LeadFlow from "./components/LeadFlow";
import DetailsMenu from "../../../components/Details/components/Header/DetailsTabs";
import Sidebar from "../../../components/Sidebar";

const LeadDetails: FC = () => {
  const location = useLocation();
  const { docId: paramsId } = useParams();
  const { data, loading, refetch } = useQuery(GetLeadDetailsDocument, {
    variables: {
      leadId: paramsId,
    },
  });

  const tab = useMemo(
    () => location.pathname.split("/").pop(),
    [location.pathname]
  );

  if (loading) return <SkeletonDetails />;

  const detailsTabContent = (
    <Sidebar
      TopComponent={DetailsMenu}
      menuItems={[
        {
          title: tab!,
          Component: Outlet,
        },
      ]}
    />
  );

  return (
    <LeadDetailsProvider leadData={data?.lead} refetch={refetch}>
      <DetailsWrapper
        detailsTabRoutes={leadDetailsTabRoutes}
        detailsTabContent={detailsTabContent}
      >
        <LeadFlow />
      </DetailsWrapper>
    </LeadDetailsProvider>
  );
};

export default LeadDetails;
