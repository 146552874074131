import { IconNames } from "../components/Icons/styles/iconNames";
import { LeadStatus, LeadTypes } from "../graphql/generated/types";
import { TFilter } from "./types";

export const cardLeadStatusFilters = () =>
  leadFilter()
    .filter((filter) => filter.name === "status")
    .flatMap((filter) => filter.options);

export const getStatusIcon = (status: LeadStatus) => {
  const statusIcon = leadFilter()
    .find((filter) => filter.label === "Status")
    ?.options.find((option) => option.filter?.status === status)?.iconName;
  return statusIcon;
};

export const leadStatus = () =>
  leadFilter().find((filter) => filter.name === "status")?.options || [];

const leadFilter = (): TFilter[] => [
  {
    label: "Tipo",
    value: [],
    urlParam: "tipo",
    name: "tipo",
    selectMultiValueDisplay: "all",
    options: [
      {
        value: "cliente",
        label: "Clientes",
        filter: {
          type: LeadTypes.Customer,
        },
      },
      {
        value: "parceiro",
        label: "Parceiros",
        filter: {
          type: LeadTypes.Partner,
        },
      },
    ],
  },
  {
    label: "Status",
    value: [],
    urlParam: "status",
    name: "status",
    selectOptionDisplay: "icon",
    selectMenuListDisplay: "buttons",
    selectMultiValueDisplay: "all",
    options: [
      {
        value: "novo",
        label: "Novo",
        filter: {
          status: LeadStatus.New,
        },
        iconName: IconNames.Pending,
      },
      {
        value: "diagnostico",
        label: "Diagnóstico",
        filter: {
          status: LeadStatus.Scrutiny,
        },
        iconName: IconNames.Scrutiny,
      },
      {
        value: "apresentacao",
        label: "Apresentação",
        filter: {
          status: LeadStatus.Pitch,
        },
        iconName: IconNames.Pitch,
      },
      {
        value: "negociacao",
        label: "Negociação",
        filter: {
          status: LeadStatus.Deal,
        },
        iconName: IconNames.Deal,
      },
      {
        value: "recusado",
        label: "Recusado",
        filter: {
          status: LeadStatus.Refused,
        },
        iconName: IconNames.Rejected,
      },
    ],
  },
];

export default leadFilter;
