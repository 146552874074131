import { Services } from "../../../graphql/generated/types";
import { LatLng } from "../../../types";
import { FormErrors } from "../services/form";

export interface FormObj {
  title?: string;
  ts?: number;
  subtitle?: string;
  id?: string;
  sections: Array<FormSection>;
  onSubmit: () => void;
  errors?: FormErrors;
}

export interface FormSection {
  title?: string;
  subtitle?: string;
  id: string;
  fields: Array<FormField>;
  label: string;
  isValid?: boolean;
}

type InputVariant = "standard" | "lg" | "xs" | undefined;
type TextInputTypes = "text" | "password" | "number";
type LabelAndCodeInputType = "labelAndCode";
type Many2OneInputType = "Many2one";
type GeoLocationInputType = "geoLocation";
type PolygonInputType = "polygon";
type ObjectListInputType = "objectList";
type FileInputType = "file";
export type SelectionInputTypes = LabelAndCodeInputType | Many2OneInputType;
export type InputTypes =
  | TextInputTypes
  | SelectionInputTypes
  | GeoLocationInputType
  | ObjectListInputType
  | PolygonInputType
  | Many2OneInputType
  | FileInputType;

interface BaseFieldType {
  inputType: FormInputTypes;
  group: string;
  type: InputTypes;
  value: any;
  name: string;
  subSection?: boolean;
  label: string;
  labelDisplay?: "title" | "subtitle" | "none";
  disabled: boolean;
  variant: InputVariant;
  required?: boolean;
  fullWidth?: boolean;
  inputProps?: any;
  handleChange?: ({ target }: React.ChangeEvent<HTMLInputElement>) => void;
  options?: Array<Option>;
  invisible: Boolean;
  accept?: string;
  divider?: string;
}

export type TInputMasks = "cpfCnpj" | "cep" | "phone" | "rg";

export interface TextFieldType extends BaseFieldType {
  type: TextInputTypes | Many2OneInputType;
  value: string;
  inputMask?: TInputMasks;
}

export interface FloatFieldType extends BaseFieldType {
  type: TextInputTypes;
  value: number | string;
  inputMask?: TInputMasks;
}
export interface FileFieldType extends BaseFieldType {
  type: "file";
  accept: string;
}

export interface LabelAndCodeFieldValue {
  label: string;
  cod_mapfre: string;
}

interface LabelAndCodeFieldType extends BaseFieldType {
  type: Many2OneInputType;
  value: LabelAndCodeFieldValue;
}

export interface Many2OneFieldValue {
  name: string;
  _id: string;
}

interface Many2ManyFieldValue {
  name: string;
  _id: string;
}

export interface Many2OneFieldType extends BaseFieldType {
  value: Many2OneFieldValue;
  serviceName: Services;
  domain: any;
}

export interface Many2ManyFieldType extends BaseFieldType {
  value: Many2ManyFieldValue;
  serviceName: Services;
  domain: any;
  multiValueDisplay?: "all" | "default";
}

export interface GeoLocationFieldType extends BaseFieldType {
  type: GeoLocationInputType;
  value: LatLng;
}

export interface PolygonFieldType extends BaseFieldType {
  type: PolygonInputType;
  value: LatLng[];
  defaultCenter: string;
}
export interface ObjectListFieldType extends BaseFieldType {
  type: ObjectListInputType;
  value: any;
  allowCopy?: boolean;
  fields: FormField[];
}

export type FormField =
  | TextFieldType
  | LabelAndCodeFieldType
  | GeoLocationFieldType
  | ObjectListFieldType
  | PolygonFieldType
  | Many2OneFieldType
  | Many2ManyFieldType;

export interface Option {
  value: string;
  label: string;
  id: string;
  name: string;
  warning: string;
}

export enum FormInputTypes {
  TEXT_FIELD = "textField",
  NUMBER = "number",
  SELECT = "select",
  MULTI_SELECT = "multiSelect",
  RADIO = "radio",
  DATE = "date",
  DROPDOWN = "dropdown",
  MANY2ONE = "many2one",
  GEO_LOCATION = "geoLocation",
  TEXT_AREA = "textArea",
  BOOLEAN = "boolean",
  OBJECT_LIST = "objectList",
  OBJECT = "object",
  FILE = "file",
  POLYGON = "polygon",
  MONETARY = "monetary",
  MANY2MANY = "many2many",
}
