/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { PropsWithChildren, FC, useState } from "react";
import Column from "../../components/Grid/Column";
import Row from "../../components/Grid/Row";
import Icon from "../../components/Icons";
import { IconNames } from "../../components/Icons/styles/iconNames";
import { IconSizes } from "../../components/Icons/styles/iconSizes";
import Layout from "../../components/Layout";
import MainFrame from "../../components/MainFrame";
import { Services } from "../../graphql/generated/types";
import { useFlags } from "../../hooks/useFlags";
import useIsMobile from "../../hooks/useIsMobile";
import { ColorNames } from "../../theme";
import DashboardCardList from "./components/Cards";
import Notifications from "./components/Notifications";
import {
  DashboardCardFC,
  TDashboardCard,
} from "./components/Cards/DashboardCard";
import Tasks from "./components/Tasks";
import PartnersActions from "./components/PartnersActions";
import { DashboardAction } from "./components/Actions";

const styles = {
  mainContent: {
    height: "100%",
    margin: 0,
  },
  columnsContainer: {
    height: "100%",
  },
  controllers: {
    position: "absolute" as "absolute",
    top: "50%",
    cursor: "pointer",
    zIndex: 100,
    "& span": {
      opacity: 0.7,
    },
  },
  controllerLeft: {
    left: 0,
  },
  controllerRight: {
    right: 0,
  },
};

enum VisibleColumn {
  Tasks,
  Cards,
  Notifications,
}

interface DashboardProps extends PropsWithChildren {
  serviceName: Services;
  redirectLocation: (docId: string) => string;
  cards: TDashboardCard[];
  CardComponent: DashboardCardFC<any>;
  dashboardActions?: DashboardAction[];
}

const Dashboard_DEPRECATED: FC<DashboardProps> = ({
  serviceName,
  redirectLocation,
  cards,
  CardComponent,
  dashboardActions,
}) => {
  const { isFlagEnabled } = useFlags();
  const isMobile = useIsMobile();
  const [visibleColumn, setVisibleColumn] = useState<VisibleColumn>(
    VisibleColumn.Tasks
  );

  const handleNextColumn = () => {
    if (visibleColumn === VisibleColumn.Notifications) {
      setVisibleColumn(VisibleColumn.Tasks);
    } else {
      setVisibleColumn(visibleColumn + 1);
    }
  };

  const handlePreviousColumn = () => {
    if (visibleColumn === VisibleColumn.Tasks) {
      setVisibleColumn(VisibleColumn.Notifications);
    } else {
      setVisibleColumn(visibleColumn - 1);
    }
  };

  return (
    <Layout>
      <MainFrame dashboardActions={dashboardActions}>
        <>
          {isMobile && (
            <>
              <div css={css(styles.controllerLeft, styles.controllers)}>
                <Icon
                  name={IconNames.SkipBack}
                  size={IconSizes.ExtraLarge}
                  color={ColorNames.GREY}
                  onClick={handlePreviousColumn}
                />
              </div>
              <div css={css(styles.controllerRight, styles.controllers)}>
                <Icon
                  name={IconNames.SkipForward}
                  size={IconSizes.ExtraLarge}
                  color={ColorNames.GREY}
                  onClick={handleNextColumn}
                />
              </div>
            </>
          )}
          <Row css={styles.mainContent} justify="center">
            {(!isMobile || visibleColumn === VisibleColumn.Tasks) && (
              <Column xs={11} lg={4} css={styles.columnsContainer}>
                {isFlagEnabled("admin-only") ? (
                  <Tasks
                    serviceName={serviceName}
                    redirectLocation={redirectLocation}
                  />
                ) : (
                  <PartnersActions />
                )}
              </Column>
            )}
            {(!isMobile || visibleColumn === VisibleColumn.Cards) && (
              <Column xs={11} lg={4} css={styles.columnsContainer}>
                <DashboardCardList
                  cards={cards}
                  CardComponent={CardComponent}
                />
              </Column>
            )}
            {(!isMobile || visibleColumn === VisibleColumn.Notifications) && (
              <Column xs={11} lg={4} css={styles.columnsContainer}>
                {isFlagEnabled("use-notifications") && (
                  <Notifications
                    serviceName={serviceName}
                    redirectLocation={redirectLocation}
                  />
                )}
              </Column>
            )}
          </Row>
        </>
      </MainFrame>
    </Layout>
  );
};

export default Dashboard_DEPRECATED;
