import { dashboardDocumentFilters } from "../filters/documents";
import { CultureObject } from "../models/Culture";
import { UserObject } from "../models/User";
import useModel from "./useModel";
import useModelFilter from "./useModelFilter";
import useUrlFilter from "./useUrlFilter";
import useUserFilter from "./useUsersFilter";

const useDashboardDocumentsFilter = () => {
  const usersModel = useModel<UserObject>("users");
  const userFilters = useUserFilter();
  const culturesModel = useModel<CultureObject>("culturas");
  const filters = useModelFilter(dashboardDocumentFilters, [
    async () =>
      usersModel
        .search(userFilters.getOption("papel", "consultor")?.filter)
        .then((res) => [
          "originator",
          res.map((originator) => ({
            value: originator._id,
            label: originator.name!,
            filter: { originatorId: originator._id },
          })),
        ]),
    async () =>
      usersModel
        .search(userFilters.getOption("papel", "administrador")?.filter)
        .then((res) => [
          "responsible",
          res.map((partner) => ({
            value: partner._id,
            label: partner.name!,
            filter: { responsibleId: partner._id },
          })),
        ]),
    async () =>
      culturesModel.search({}).then((res) => [
        "culture",
        res.map((culture) => ({
          value: culture._id,
          label: culture.name!,
          filter: { cultureId: culture._id },
        })),
      ]),
  ]);

  useUrlFilter(filters);

  return filters;
};

export default useDashboardDocumentsFilter;
