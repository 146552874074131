/** @jsxImportSource @emotion/react */
import { FC } from "react";

import {
  useCountContactsQuery,
  useListContactsQuery,
} from "../../../graphql/generated/types";
import CardList from "../../../components/CardList";
import Layout from "../../../components/Layout";
import { ContactsLocation, ToContactDetailsLocation } from "../../../Locations";
import ContactCard from "./ContactCard";
import MainFrame from "../../../components/MainFrame";
import { Outlet, useNavigate } from "react-router-dom";
import { useUIStore } from "../../../hooks/useUIStore";
import useContactsFilter from "../../../hooks/useContactsFilter";
import Paginator from "../../../components/Paginator";
import usePagination from "../../../hooks/usePagination";

const Contacts: FC = () => {
  const contactsFilter = useContactsFilter();
  const filter = contactsFilter.composeQuery();
  const navigate = useNavigate();
  const { toggleDrawerSize } = useUIStore();
  const { skip, limit, currentPage, onPaginatorChange } = usePagination(9);

  const { data, loading } = useListContactsQuery({
    variables: {
      filter,
      sort: { updatedAt: -1 },
      skip,
      limit,
    },
  });

  const countContactsQuery = useCountContactsQuery({
    variables: {
      filter,
    },
  });

  // TODO: Remover depois que fizer merge com PM-2147-table-actions
  // useEffect(() => {
  //   if (contacts) {
  //     setTableData(
  //       contacts.map((doc) => ({
  //         name: doc.name,
  //         type: doc.tags?.[0] && userTagLabels[doc.tags?.[0]],
  //         uf: doc.address?.state,
  //         status: doc.user?.status && userStatusLabels[doc.user?.status],
  //         responsible: doc.responsible?.name,
  //         cnpj: doc.cpfCnpj,
  //         email: doc.email,
  //         date: new Date(doc.createdAt).toLocaleDateString("pt-br"),
  //       }))
  //     );
  //   }
  // }, [contacts]);

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(ContactsLocation)}
        onToggle={toggleDrawerSize}
        drawerParamKey="contactId"
        filter={contactsFilter}
      >
        <CardList
          // TODO: Fix type in PM-2245
          data={(data?.contacts as any[]) || []}
          redirectLocation={ToContactDetailsLocation}
          CardComponent={ContactCard}
          loading={loading}
        />
        <Paginator
          total={countContactsQuery.data?.countContacts || 0}
          limit={limit}
          onPaginatorChange={onPaginatorChange}
          currentPage={currentPage}
        />
      </MainFrame>
    </Layout>
  );
};

export default Contacts;
