import { AuthContextInterface } from "../contexts/auth";
import serviceFetch from "./fetch";
import { QuotationsObject } from "../models/Quotation";

export const getQuotationsByRequest = async (
  auth: AuthContextInterface,
  request: string
): Promise<{ data: Array<QuotationsObject> }> =>
  serviceFetch(auth, `cotacoes/?${request}`, null, "get");

export const getQuotationById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: QuotationsObject }> =>
  serviceFetch(auth, `cotacoes/${id}`, null, "get");
