/** @jsxImportSource @emotion/react */

import { CSSProperties, FC, useEffect, useState } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import Typography from "../Typography";
import { css } from "@emotion/react";
import { theme } from "../../theme";

const getCSSRules: CSSRulesResolver<InputProps> = ({ ...props }) => ({
  checkboxWrapper: {
    width: "fit-content",
    padding: 2,
    height: 20,
    cursor: "pointer",
    marginBottom: 4,
    textAlign: "initial " as CSSProperties["textAlign"],
    fontSize: 20,
    "& input:checked": {
      backgroundColor: `${props.colors.Green[100]}`,
      position: "relative",
    },
    "& input:disabled": {
      backgroundColor: `${props.colors.Black[20]}`,
    },
    "& input:checked::before": {
      content: '"✔"',
      fontSize: 14,
      color: "white",
      position: "absolute",
      left: 1,
      top: -3,
    },
    "& input:checked + span": {
      color: `${props.colors.Green[100]}`,
    },
    "& input:disabled + span": {
      color: `${props.colors.Black[20]}`,
    },
  },
  checkbox: {
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    font: "inherit",
    width: 15,
    height: 15,
    borderRadius: "20%",
    margin: 0,
    outline: "none",
    border: "0.2px solid #bbbbbb",
    boxShadow: theme.boxShadow.default,
  },
  typograph: {
    marginLeft: "0.5rem",
  },
});

interface InputProps {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

const Checkbox: FC<InputProps> = ({
  label,
  checked,
  disabled,
  onChange,
  ...props
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = () => {
    setIsChecked((prev) => !prev);
    onChange && onChange();
  };

  const checkboxCursor = {
    cursor: checked || disabled ? "not-allowed" : "pointer",
  };

  useEffect(() => setIsChecked(checked), [checked]);

  return (
    <div css={styles.checkboxWrapper}>
      <label>
        <input
          type="checkbox"
          css={css(styles.checkbox, checkboxCursor)}
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
        <Typography
          css={css(styles.typograph, checkboxCursor)}
          variant="textMedium"
          component={"span"}
        >
          {label}
        </Typography>
      </label>
    </div>
  );
};

export default Checkbox;
