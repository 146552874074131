import { FC } from "react";
import useForm from "./hooks/useForm.v2";
import FormSections from "./form.sections";
import ScrollContainer from "../../components/ScrollContainer";

const FormContentV2: FC = () => {
  const { form } = useForm();
  if (!form) return null;
  return (
    <ScrollContainer>
      <FormSections sections={form.sections} />
    </ScrollContainer>
  );
};

export default FormContentV2;
