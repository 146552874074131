/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ToSimulationLocation } from "../../../../../Locations";
import Button from "../../../../../components/Button";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import { useAuth } from "../../../../../hooks/useAuth";

const ActionButtons: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleShareProviderLink = () => {
    const { protocol, host } = window.location;
    const shareLink = `${protocol}//${host}/${
      user.org?.slug || user.contact.slug
    }`;
    navigator.clipboard.writeText(shareLink);
    alert(`Link copiado: ${shareLink}`);
  };

  const handleNewSimulation = () => {
    navigate(ToSimulationLocation("new", "simulacao"));
  };

  return (
    <>
      <Button
        style="outlinePrimary"
        text="Nova Cotação"
        iconName={IconNames.Add}
        width="auto"
        onClick={handleNewSimulation}
      />
      <Button
        style="outlinePrimary"
        text="Link para Compartilhar"
        iconName={IconNames.Share}
        width="auto"
        onClick={handleShareProviderLink}
      />
      <Button
        style="outlinePrimary"
        text="Materiais de Apoio"
        width="auto"
        disabled
        onClick={() => {}}
      />
      <Button
        style="outlinePrimary"
        text="Métricas"
        width="auto"
        disabled
        onClick={() => {}}
      />
    </>
  );
};

export default ActionButtons;
