import useModelFilter from "./useModelFilter";
import useUrlFilter from "./useUrlFilter";
import leadFilters from "../filters/leads";
import { CountLeadsDocument } from "../graphql/generated/types";

const useLeadsFilter = () => {
  const filters = useModelFilter(leadFilters, [], CountLeadsDocument);

  useUrlFilter(filters);

  return filters;
};

export default useLeadsFilter;
