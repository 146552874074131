import { Contact, ContactTags } from "../../../graphql/generated/types";
import { UserObject } from "../../../models/User";

export const isDocumentRelatedContact = (tags: ContactTags[]) =>
  tags.some((tag) =>
    [
      ContactTags.Farmer,
      ContactTags.AllowedPerson,
      ContactTags.Beneficiary,
      ContactTags.PaymentResponsible,
    ].includes(tag)
  );

export const isOrg = (contact?: Contact) => {
  return !!contact?.tags?.includes(ContactTags.Organization);
};

export const isConsultant = (contact?: Contact) => {
  return !!contact?.tags?.includes(ContactTags.Consultant);
};

export const orgIsCompleted = (user?: UserObject) =>
  Boolean(user?.org?.cpfCnpj && user?.org?.address && user?.contact?.rg);
