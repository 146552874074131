import { FC, useMemo } from "react";
import { DetailsRowProps } from "../../../../../components/Details/components/Main/Grid/DetailsRow";
import useCultureDetails from "../../../hooks/useCulture";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { formatPrice } from "../../../../../utils/formatNumber";
import EditableSection from "../../../../../components/EditableSection";
import {
  GetEditCultureFormResponseDocument,
  GetEditCultureFormResponseQueryVariables,
} from "../../../../../graphql/generated/types";

const Summary: FC = () => {
  const { culture, refetch } = useCultureDetails();
  const cultureInfo = useMemo(() => {
    return [
      {
        label: "Nome",
        value: culture.name!,
      },
      {
        label: "Status",
        value: culture.active ? "Ativo" : "Inativo",
      },
      {
        label: "Valor de Mercado",
        value: culture.currentSackValue
          ? `${formatPrice(culture.currentSackValue)} / Saca`
          : "",
      },
    ] as DetailsRowProps[];
  }, [culture]);
  return (
    <EditableSection<{}, GetEditCultureFormResponseQueryVariables>
      title="Dados da Cultura"
      main
      editQuery={GetEditCultureFormResponseDocument}
      objectId={culture._id}
      onSubmit={() => refetch()}
      useV2
    >
      <DetailsSectionColumns columns={[cultureInfo]} />
    </EditableSection>
  );
};

export default Summary;
