import Table from "../../../../components/Table";
import { useListPaymentsQuery } from "../../../../graphql/generated/types";
import useContactDetails from "../../../../hooks/useContactDetails";
import { ColorNames } from "../../../../theme";
import { ToDocumentDetailsLocation } from "../../../../Locations";
import { formatPrice } from "../../../../utils/formatNumber";
import { formatISODate } from "../../../../utils/dateUtils";
import { PaymentStatusLabels } from "../../../Documents/DocumentDetails/sections/Policy/Payments";

type TablePaymentsData = {
  _id: string;
  name: string;
  amount: string;
  documentId: string;
  dateDue: string;
  status: string;
};

const ContactPayments = () => {
  const { contactData } = useContactDetails();

  const { data } = useListPaymentsQuery({
    variables: {
      filter: { contactId: contactData._id! },
    },
  });

  const tablePaymentsData: TablePaymentsData[] = (data?.payments || []).map(
    (payment) => ({
      ...payment,
      amount: formatPrice(payment.amount),
      dateDue: formatISODate(payment.dateDue),
      status: PaymentStatusLabels[payment.status],
    })
  );

  return (
    <Table<TablePaymentsData>
      data={tablePaymentsData}
      withBorder
      color={ColorNames.BLUE}
      rowLink={(payment: TablePaymentsData) =>
        ToDocumentDetailsLocation(payment.documentId!)
      }
      columns={[
        {
          label: "Parcela",
          key: "name",
        },
        {
          label: "Vencimento",
          key: "dateDue",
        },
        {
          label: "Valor",
          key: "amount",
        },
        {
          label: "Status",
          key: "status",
        },
      ]}
    />
  );
};

export default ContactPayments;
