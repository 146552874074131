import { useMemo } from "react";
import { Contact } from "../../../graphql/generated/types";
import Card, { CardFC } from "../../../components/CardList/components/Card";
import { ColorNames } from "../../../theme";
import Responsibles from "../../Documents/DocumentCards/components/Responsibles";
import { formatISODate } from "../../../utils/dateUtils";

const ContactCard: CardFC<Contact> = ({ data, active, onClick }) => {
  const cardData = useMemo(() => {
    return {
      line1: data.name || "",
      line2: data.cpfCnpj || "",
      line3: data.email || "",
      line4: formatISODate(data.createdAt) || "",
    };
  }, [data]);

  const responsibles = useMemo(() => {
    const respName = data?.responsible?.name;
    return respName ? [respName] : [];
  }, [data]);

  return (
    <Card
      cardData={cardData}
      detailBottom={
        <Responsibles
          responsibles={responsibles}
          color={ColorNames.VALID_GREEN}
          docId={data._id!}
        />
      }
      color={ColorNames.GREEN}
      active={active}
      onClick={onClick}
    />
  );
};

export default ContactCard;
