import React from "react";
import { DocumentStages } from "../../../../graphql/generated/types";
import Chip from "../../../../components/Chip";
import useDocumentColor from "../../../../hooks/useDocumentColor";
import { documentStageLabels } from "../../../../shared/documents/documentStage";

interface StageChipProps {
  stage: DocumentStages;
}

const StageCell: React.FC<StageChipProps> = ({ stage }) => {
  const label = documentStageLabels[stage]?.toUpperCase() || "-";
  const color = useDocumentColor(stage);
  return <Chip color={color} label={label} />;
};

export default StageCell;
