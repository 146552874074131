import { FC } from "react";
import Row from "../../../../components/Grid/Row";
import Typography from "../../../../components/Typography";
import { ColorNames } from "../../../../theme";
import ObjectFieldSummary from "./ObjectFieldSummary";
import { FormFieldV2 } from "../../../../graphql/generated/types";

type ObjectListFieldSummaryProps = {
  field: FormFieldV2;
};

const ObjectListFieldSummary: FC<ObjectListFieldSummaryProps> = ({
  field: { fields, label },
}) => {
  return (
    <>
      {fields!.map((itemField, index) => (
        <Row key={itemField.fieldPath}>
          <Typography
            variant="textLarge"
            component="div"
            align="left"
            color={ColorNames.GREEN}
          >
            {`${label} ${index + 1}${
              itemField.label && ` - ${itemField.label}`
            }`}
          </Typography>
          <ObjectFieldSummary field={itemField} />
        </Row>
      ))}
    </>
  );
};

export default ObjectListFieldSummary;
