/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Column from "../../components/Grid/Column";
import Row from "../../components/Grid/Row";
import Layout from "../../components/Layout";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import {
  DocumentsDashboardLocation,
  CoorporativeDashboardLocation,
  PartnersDashboardLocation,
} from "../../Locations";
import { Services } from "../../graphql/generated/types";
import { useFlags } from "../../hooks/useFlags";
import { Fragment, useEffect } from "react";

const getCSSRules: CSSRulesResolver = () => ({
  main: {
    height: "100%",
    boxSizing: "border-box",
  },
});

export default function Home() {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const navigate = useNavigate();
  const { isFlagEnabled, flagsLoaded } = useFlags();

  const appList = [
    {
      serviceName: Services.Documents,
      label: "Seguros",
      path: DocumentsDashboardLocation,
    },
    {
      serviceName: Services.Users,
      label: "Parceiros",
      path: PartnersDashboardLocation,
      flag: "partners-view",
    },
    {
      serviceName: Services.Leads,
      label: "Corporativo",
      path: CoorporativeDashboardLocation,
      flag: "leads-view",
    },
  ];

  const appsToShow = appList.filter(
    (app) => !app.flag || isFlagEnabled(app.flag)
  );

  useEffect(() => {
    if (appsToShow.length === 1 && flagsLoaded) {
      navigate(appsToShow[0].path);
    }
  }, [flagsLoaded]);

  return (
    <Layout>
      <Row justify="center" align="center" css={styles.main}>
        <Column xs={12} sm={8} md={6}>
          <Row justify="center">
            {appsToShow.map((app, i) => (
              <Fragment key={`app-${i}`}>
                {(!app.flag || isFlagEnabled(app.flag)) && (
                  <Column xs={12} sm={4}>
                    <Button
                      text={app.label}
                      onClick={() => navigate(app.path)}
                      style="outlinePrimary"
                    />
                  </Column>
                )}
              </Fragment>
            ))}
          </Row>
        </Column>
      </Row>
    </Layout>
  );
}
