import { useMemo } from "react";
import Card, { CardFC } from "../../components/CardList/components/Card";
import { Lead } from "../../graphql/generated/types";
import Icon from "../../components/Icons";
import useLeadColor from "../../hooks/useLeadColor";
import { cardLeadStatusFilters } from "../../filters/leads";
import { formatISODate } from "../../utils/dateUtils";

const LeadCard: CardFC<Lead> = ({ data, active, onClick }) => {
  const cultures = useMemo(() => {
    return data.cultures?.map((culture) => culture.name).join(", ");
  }, [data.cultures]);
  const cardData = useMemo(
    () => ({
      line1: data.name || "",
      line2: cultures,
      line3: data.address?.city
        ? `${data.address?.city} ${
            data.address?.state && "//" + data.address?.state
          }`
        : "",
      line4: formatISODate(data.createdAt) || "",
    }),
    [data]
  );

  const cardColor = useLeadColor(data.type as string);
  const statusIcon = () => {
    return cardLeadStatusFilters().find(
      (option) => option.filter.status === data.status
    )?.iconName;
  };

  return (
    <Card
      detailTop={<Icon name={statusIcon()!} color={cardColor} />}
      cardData={cardData}
      color={cardColor}
      active={active}
      onClick={onClick}
    />
  );
};

export default LeadCard;
