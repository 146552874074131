import { useParams } from "react-router-dom";
import Form from "../../../modules/Forms";
import { useUIStore } from "../../../hooks/useUIStore";
import FormV2 from "../../../modules/Forms/form.v2";

const DirectQuotationForm = () => {
  const { formId, docId } = useParams();
  const { openSubmittedModal } = useUIStore();

  const onSubmit = async () => {
    openSubmittedModal(
      "Obrigado pelas informações! Entraremos em contato em breve."
    );
  };

  if (formId === "equipamentos") {
    return <FormV2 formResponseId={docId!} onSubmit={onSubmit} />;
  }

  return (
    <>
      {docId && formId && docId !== "new" && (
        <Form formId={formId} docId={docId} onSubmit={onSubmit} />
      )}
    </>
  );
};

export default DirectQuotationForm;
