import React from "react";
import { InsuranceTypeGroupEnum } from "../../../../graphql/generated/types";
import IconLabel from "../../../../components/IconLabel";
import useInsuranceIcon from "../../../../hooks/useInsuranceIcon";
import { insuranceTypeGroupLabels } from "../../../../shared/insurance/insuranceTypeGroup";

interface InsuranceCellProps {
  insuranceType: InsuranceTypeGroupEnum;
}

const InsuranceCell: React.FC<InsuranceCellProps> = ({ insuranceType }) => {
  const label = insuranceTypeGroupLabels[insuranceType]?.toUpperCase() || "-";
  const icon = useInsuranceIcon(insuranceType);

  return <IconLabel label={label} icon={icon} />;
};

export default InsuranceCell;
