/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useState } from "react";
import {
  ContactTags,
  UserRoles,
  UserStatus,
} from "../../../../graphql/generated/types";
import useCultureOptions from "../../../../hooks/useCultureOptions";
import useDebounce from "../../../../hooks/useDebounce";
import { DetailsRowProps } from "../../../../components/Details/components/Main/Grid/DetailsRow";
import Typography from "../../../../components/Typography";
import Icon from "../../../../components/Icons";
import UserSelect from "../../../../components/Details/components/Main/UserSelect";
import RatingStars from "../../../../components/RatingStars";
import BooleanInput from "../../../../components/Input/BooleanInput";
import TextAreaInput from "../../../../components/Input/TextAreaInput";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { SectionFooterButton } from "../../../Documents/DocumentDetails/components/SectionFooter";
import DetailsSection from "../../../../components/Details/components/Main/Section/DetailsSection";
import DetailsSectionColumns from "../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { ColorNames } from "../../../../theme";
import { getStatusIcon } from "../../../../filters/users";
import { formatDateWithTime } from "../../../../utils/dateUtils";
import MultiSelectInput from "../../../../components/Input/SelectInputs/MultiSelectInput";
import useContactDetails from "../../../../hooks/useContactDetails";
import { industryLabels } from "../../../../shared/org/industries";
import { operationalAreaLabels } from "../../../../shared/org/operationalArea";
import { orgMembershipCountLabels } from "../../../../shared/org/membershipCount";
import { orgDepartmentRoleLabels } from "../../../../shared/org/departmentRoles";
import HR from "../../../../components/HorizontalLine";
import { userStatusLabels } from "../../../../shared/user/userStatus";
import ModalStoreProvider from "../../../../components/Modal/context/ModalStore";
import { isConsultant, isOrg } from "../../utils/tags";
import { EditableCommission } from "./EditableCommission";

const ContactSummary = () => {
  const {
    contactData,
    handleAddComment,
    handleSetResponsible,
    handleAddRating,
    handleApprovePartner,
    handleRejectPartner,
    handleDisablePartner,
    handleEnablePartner,
    handleAddCultureToPartner,
    handleAddCourse,
    handleUpgradeToAdmin,
  } = useContactDetails();
  const { user, status } = contactData;
  const cultures = useCultureOptions();
  const [comment, setComment] = useState<string>(contactData.comments || "");
  const { debouncedValue, cancelDebounce } = useDebounce(comment, 1000);

  const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleAddCulture = (value: string[]) => {
    handleAddCultureToPartner(value);
  };

  useEffect(() => {
    if (debouncedValue !== contactData.comments) {
      handleAddComment(debouncedValue);
    }
  }, [debouncedValue]);

  const handleBlur = () => {
    cancelDebounce(), handleAddComment(comment);
  };

  const leffColumn = useMemo(() => {
    const isDisabled = !!user?.disabled;
    const data: DetailsRowProps[] = [
      {
        label: "Avaliação de potencial",
        value: (
          <RatingStars
            rating={contactData.rating!}
            onClick={(option) => handleAddRating(option)}
            color={ColorNames.GREEN}
          />
        ),
      },
      {
        label: "Culturas Relacionadas",
        value: (
          <MultiSelectInput
            placeholder="Culturas do parceiro"
            inputName="culture"
            onSelect={(value) => handleAddCulture(value)}
            value={contactData.cultureIds}
            options={cultures.cultureOptions}
            multiValueDisplay="all"
          />
        ),
      },
    ];
    if (status) {
      data.push({
        label: "Status",
        value: (
          <Typography
            variant="textMedium"
            component="div"
            css={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <Icon
              name={isDisabled ? IconNames.Archive : getStatusIcon(status)!}
              color={isDisabled ? ColorNames.ERROR_RED : ColorNames.GREEN}
            />
            {isDisabled
              ? "Desabilitado"
              : userStatusLabels[status as UserStatus]}
          </Typography>
        ),
      });
    }
    if (user) {
      data.push(
        {
          label: "Assinatura",
          value: formatDateWithTime(user?.signedDate),
        },
        {
          label: "Ultimo acesso",
          value: formatDateWithTime(user?.lastAccessAt),
        }
      );
    }
    if (isConsultant(contactData) || isOrg(contactData)) {
      data.push({
        label: "Comissão",
        value: <EditableCommission />,
      });
    }

    return data;
  }, [contactData, cultures.cultureOptions]);

  const rightColumn = useMemo(() => {
    const baseInfo = [
      {
        label: "Data de criação",
        value: formatDateWithTime(contactData.createdAt),
      },
      {
        label: "Comentários",
        value: (
          <TextAreaInput
            value={comment}
            onChange={handleComment}
            onBlur={handleBlur}
            name="comments"
          />
        ),
      },
      {
        label: "Responsável",
        value: (
          <UserSelect
            domain={{ role: UserRoles.Admin }}
            value={contactData.responsible?._id || ""}
            placeholder=""
            onSelect={(option) => handleSetResponsible(option?.value)}
            inputName="responsavel-contato"
            isClearable
          />
        ),
      },
    ];
    if (!user) return baseInfo;
    return [
      ...baseInfo,
      {
        label: "Usuário fez o curso?",
        value: (
          <BooleanInput
            name="finishedCourse"
            label=""
            value={user.finishedCourse!}
            handleChange={(e) => handleAddCourse(e.target.value)}
          />
        ),
      },
    ];
  }, [comment, contactData]);

  const orgColumns = useMemo(() => {
    const isOrgMember = contactData.tags?.some((t) =>
      [ContactTags.OrgAdmin, ContactTags.OrgMember].includes(t)
    );
    if (isOrgMember) {
      return [
        [
          {
            label: "Cargo de organização",
            value: orgDepartmentRoleLabels[contactData.departmentRole!],
          },
        ],
      ];
    }

    if (isOrg(contactData)) {
      const leftSide = [
        {
          label: "Setor da organização",
          value: industryLabels[contactData.industry!],
        },
        {
          label: "Cidades",
          value: contactData.cities?.join(", "),
        },
        {
          label: "Número de filiais",
          value: contactData.subsidiariesCount,
        },
      ];
      const rightSide = [
        {
          label: "Área de atuação",
          value: contactData.operationalArea
            ? operationalAreaLabels[contactData.operationalArea]
            : "-",
        },
        {
          label: "Número de produtores/cooperados",
          value: contactData.membershipCount
            ? orgMembershipCountLabels[contactData.membershipCount]
            : "-",
        },
      ];
      return [leftSide, rightSide];
    }
  }, [contactData]);

  const footerButtons = useMemo(() => {
    const approveAndRejectButtons =
      status !== UserStatus.Registered
        ? []
        : [
            {
              text: "Rejeitar",
              iconName: IconNames.Rejected,
              onClick: () => handleRejectPartner(),
            },
            {
              text: "Aprovar",
              iconName: IconNames.Valid,
              onClick: () => handleApprovePartner(),
            },
          ];
    if (user)
      return [
        !user.disabled && {
          text: "Desabilitar",
          iconName: IconNames.Archive,
          onClick: () => handleDisablePartner(),
        },
        user.disabled && {
          text: "Reabilitar",
          iconName: IconNames.Unarchive,
          onClick: () => handleEnablePartner(),
        },
        user.role === UserRoles.Consultant &&
          user.status === UserStatus.Active && {
            text: "Tornar admin",
            iconName: IconNames.User,
            onClick: () => handleUpgradeToAdmin(),
          },
        ...approveAndRejectButtons,
      ].filter(Boolean) as SectionFooterButton[];
    if (contactData.status) {
      return approveAndRejectButtons;
    }

    return [];
  }, [contactData, user]);
  return (
    <ModalStoreProvider>
      <DetailsSection
        title="Detalhes"
        noTitle
        main
        footerButtons={footerButtons}
      >
        <DetailsSectionColumns columns={[leffColumn, rightColumn]} />
        {orgColumns && (
          <>
            <HR />
            <DetailsSectionColumns columns={orgColumns} />
          </>
        )}
      </DetailsSection>
    </ModalStoreProvider>
  );
};

export default ContactSummary;
