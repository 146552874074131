import { FC, useMemo } from "react";

import DefaultFieldSummary from "./DefaultFieldSummary";
import { formatInputMask, formatPrice } from "../../../../utils/formatNumber";
import { TInputMasks } from "../../models/formInterfaces";
import ObjectFieldSummary from "./ObjectFieldSummary";
import ObjectListFieldSummary from "./ObjectListFieldSummary";
import Many2XFieldSummary from "./Many2XFieldSummary";
import { formatISODate } from "../../../../utils/dateUtils";

import {
  FormFieldV2,
  FormInputTypes,
} from "../../../../graphql/generated/types";
import VariableFieldColumn from "../utils/VariableFieldColumn";

type FormFieldSummaryProps = {
  field: FormFieldV2;
};

const FormFieldSummary: FC<FormFieldSummaryProps> = ({ field }) => {
  const { inputType, invisible, value, inputMask, variant } = field;
  if (invisible) {
    return null;
  }
  const summaryField = useMemo(() => {
    switch (inputType) {
      case FormInputTypes.Number:
      case FormInputTypes.TextField:
        return (
          <DefaultFieldSummary
            value={formatInputMask(value, inputMask as TInputMasks)}
          />
        );
      case FormInputTypes.Monetary:
        return <DefaultFieldSummary value={formatPrice(value)} />;
      case FormInputTypes.Date:
        return (
          <DefaultFieldSummary value={value ? formatISODate(value) : ""} />
        );
      case FormInputTypes.File:
        return <DefaultFieldSummary value={value?.fileName || ""} />;
      case FormInputTypes.Object:
        return <ObjectFieldSummary field={field} />;
      case FormInputTypes.ObjectList:
        return <ObjectListFieldSummary field={field} />;
      case FormInputTypes.Dropdown:
      case FormInputTypes.CityList:
        return (
          <DefaultFieldSummary
            value={value instanceof Array ? value.join(" / ") : value}
          />
        );
      case FormInputTypes.Many2one:
      case FormInputTypes.Many2many:
        return <Many2XFieldSummary field={field} />;
      default:
        return null;
    }
  }, [inputType, invisible, value, inputMask]);

  return (
    <VariableFieldColumn variant={variant}>{summaryField}</VariableFieldColumn>
  );
};

export default FormFieldSummary;
