import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import Column from "../../../../components/Grid/Column";
import useDebouncedMaskedValue from "./useMaskedDebouncedValue";
import DateInput from "../../../../components/Input/DateInput";
import FormLabel from "../FormLabel";

interface DateFieldProps {
  field: FormFieldV2;
}

const DateField: FC<DateFieldProps> = ({ field }) => {
  const { name, label, value, disabled } = field;

  const { handleChange, editing, startEditing, finishEditing, error } =
    useFormField(field);

  const { localValue, handleInputChange } = useDebouncedMaskedValue(
    value,
    editing,
    handleChange
  );

  return (
    <Column xs={12} useYPaddings>
      <FormLabel>{label.toUpperCase()}</FormLabel>
      <DateInput
        placeholder={label.toLowerCase()}
        disabled={!!disabled}
        name={name}
        value={localValue}
        onChange={handleInputChange}
        onFocus={startEditing}
        onBlur={finishEditing}
        errorMessage={error}
      />
    </Column>
  );
};

export default DateField;
