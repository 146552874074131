// TODO: Remover esse arquivo
import { Outlet, useNavigate } from "react-router-dom";
import CardList from "../../components/CardList";
import { useUIStore } from "../../hooks/useUIStore";
import MainFrame from "../../components/MainFrame";
import {
  useCountLeadsQuery,
  useListLeadsQuery,
} from "../../graphql/generated/types";
import { LeadsLocation, ToLeadDetailsLocation } from "../../Locations";
import Layout from "../../components/Layout";
import useLeadsFilter from "../../hooks/useLeadsFilter";
import LeadCard from "./LeadCard";
import Paginator from "../../components/Paginator";
import usePagination from "../../hooks/usePagination";

const Leads = () => {
  const navigate = useNavigate();
  const { toggleDrawerSize } = useUIStore();

  const leadFilters = useLeadsFilter();
  const { skip, limit, currentPage, onPaginatorChange } = usePagination(9);

  const { data, loading } = useListLeadsQuery({
    variables: {
      filter: leadFilters.composeQuery(),
      skip,
      limit,
    },
  });

  const countLeadsQuery = useCountLeadsQuery({
    variables: {
      filter: leadFilters.composeQuery(),
    },
  });

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(LeadsLocation)}
        onToggle={toggleDrawerSize}
        filter={leadFilters}
      >
        <CardList
          data={(data?.leads as any[]) || []}
          redirectLocation={ToLeadDetailsLocation}
          CardComponent={LeadCard}
          loading={loading}
        />
        <Paginator
          total={countLeadsQuery.data?.countLeads || 0}
          limit={limit}
          onPaginatorChange={onPaginatorChange}
          currentPage={currentPage}
        />
      </MainFrame>
    </Layout>
  );
};

export default Leads;
