import { FC } from "react";
import Container from "../../../../components/Grid/Container";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";
import SectionTitle from "../SectionTitle";
import FormFieldSummary from "./FormFieldSummary";
import { FormSectionV2 } from "../../../../graphql/generated/types";

type FormSectionSummaryProps = {
  section: FormSectionV2;
  handleEditSection: () => void;
};

const FormSectionSummary: FC<FormSectionSummaryProps> = ({
  section,
  handleEditSection,
}) => {
  return (
    <Container>
      <Row noMargins noGutters>
        <Column useYPaddings xs={12}>
          <SectionTitle sectionData={section} handleEdit={handleEditSection} />
        </Column>
        {section.fields.map((field) => (
          <FormFieldSummary key={field.fieldPath} field={field} />
        ))}
      </Row>
    </Container>
  );
};

export default FormSectionSummary;
