import React from "react";
import IconLabel from "../../../../components/IconLabel";
import { DocumentStatus } from "../../../../graphql/generated/types";
import useStatusIcon from "../../../../hooks/useStatusIcon";
import { documentStatusLabels } from "../../../../shared/documents/documentStatus";

interface StatusCellProps {
  status: DocumentStatus;
}

const StatusCell: React.FC<StatusCellProps> = ({ status }) => {
  const label = documentStatusLabels[status];
  const icon = useStatusIcon(status);
  return <IconLabel label={label.toUpperCase()} icon={icon} />;
};

export default StatusCell;
