/** @jsxImportSource @emotion/react */

import { FC, useEffect, useMemo, useState } from "react";
import useLeadDetails from "../../../../hooks/useLeadDetails";
import TextAreaInput from "../../../../components/Input/TextAreaInput";
import {
  GetEditLeadOnFormResponseDocument,
  GetEditLeadOnFormResponseQueryVariables,
  LeadStatus,
  LeadTypes,
  UserRoles,
} from "../../../../graphql/generated/types";
import SelectInput, {
  SelectInputOptionInterface,
} from "../../../../components/Input/SelectInputs/SelectInput";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { getStatusIcon, leadStatus } from "../../../../filters/leads";
import RatingStars from "../../../../components/RatingStars";
import { ColorNames } from "../../../../theme";
import useDebounce from "../../../../hooks/useDebounce";
import EditableSection from "../../../../components/EditableSection";
import DetailsSectionColumns from "../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import UserSelect from "../../../../components/Details/components/Main/UserSelect";
import useLeadColor from "../../../../hooks/useLeadColor";
import { DetailsRowProps } from "../../../../components/Details/components/Main/Grid/DetailsRow";
import { DetailsRowValueVariants } from "../../../../components/Details/components/Main/Grid/DetailsRowValue";
import Icon from "../../../../components/Icons";
import Typography from "../../../../components/Typography";
import LeadDataSection from "./LeadDataSection";

const statusOptions: SelectInputOptionInterface[] = leadStatus().map(
  (status) => ({
    label: status.label,
    value: status.filter?.status,
    iconName: status.iconName as IconNames,
  })
);

export const LeadTypeLabels = {
  [LeadTypes.Customer]: "Cliente",
  [LeadTypes.Partner]: "Parceiro",
};

const LeadStatusLabels = {
  [LeadStatus.New]: "Novo",
  [LeadStatus.Scrutiny]: "Diagnóstico",
  [LeadStatus.Pitch]: "Apresentação",
  [LeadStatus.Deal]: "Negociação",
  [LeadStatus.Refused]: "Recusado",
};

const LeadSummary: FC = () => {
  const {
    leadData,
    handleChangeLeadStatus,
    handleRateLead,
    addCommentToLead,
    handleAssignCommercialResponsible,
    handleAssignTechnicalResponsible,
    refetch,
  } = useLeadDetails();
  const [comment, setComment] = useState<string>(leadData?.comments || "");
  const { debouncedValue, cancelDebounce } = useDebounce(comment, 500);
  const color = useLeadColor(leadData?.type!);

  const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    if (debouncedValue !== leadData?.comments) {
      addCommentToLead(debouncedValue);
    }
  }, [debouncedValue]);

  const handleBlur = () => {
    cancelDebounce(), addCommentToLead(comment);
  };

  const leadInfo = useMemo<DetailsRowProps[]>(() => {
    return [
      {
        label: "Papel // Status",
        value: (
          <Typography
            variant="textMedium"
            component="div"
            css={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <Icon
              name={getStatusIcon(leadData.status as LeadStatus)!}
              color={
                leadData.status === LeadStatus.Refused
                  ? ColorNames.ERROR_RED
                  : color
              }
            />
            {`${LeadTypeLabels[leadData?.type! as LeadTypes]} ${
              LeadStatusLabels[leadData?.status! as LeadStatus]
            }`}
          </Typography>
        ),
      },
      {
        label: LeadTypeLabels[leadData.type! as LeadTypes],
        value: { text: leadData.name!, Component: LeadDataSection },
        variant: DetailsRowValueVariants.Modal,
      },
      {
        label: "culturas",
        value: leadData.cultures?.map((c) => c.name).join(", "),
      },
      {
        label: "área",
        value: `${leadData.area ? leadData.area + " ha" : ""}`,
      },
      {
        label: "localização",
        value: `${leadData.address?.city} ${
          leadData.address?.state && "//" + leadData.address?.state
        }`,
      },
    ];
  }, [leadData]);

  const selectInfo = useMemo(() => {
    if (!leadData) return [];
    return [
      {
        label: "Status",
        value: (
          <SelectInput
            optionDisplay="icon"
            inputName="status"
            placeholder="Selecione um status"
            options={statusOptions}
            value={leadData.status}
            color={color as unknown as ColorNames}
            onSelect={(option) =>
              handleChangeLeadStatus(option.value as LeadStatus)
            }
          />
        ),
      },
      {
        label: "Responsável Comercial",
        value: (
          <UserSelect
            domain={{ role: UserRoles.Admin }}
            value={leadData.commercialResponsibleId || ""}
            placeholder=""
            onSelect={(option) =>
              handleAssignCommercialResponsible(option!.value)
            }
            inputName={"lead-commercial-responsible"}
          />
        ),
      },
      {
        label: "Responsável Técnico",
        value: (
          <UserSelect
            domain={{ role: UserRoles.Admin }}
            value={leadData.technicalResponsibleId || ""}
            placeholder=""
            onSelect={(option) =>
              handleAssignTechnicalResponsible(option!.value)
            }
            inputName={"lead-technical-responsible"}
          />
        ),
      },
    ];
  }, [leadData]);

  const additonalInfo = useMemo(() => {
    if (!leadData) return [];
    return [
      {
        label: "Potencial",
        value: (
          <RatingStars
            rating={leadData.rating!}
            onClick={(option) => handleRateLead(option)}
            color={color as unknown as ColorNames}
          />
        ),
      },
      {
        label: "Comentário",
        value: (
          <TextAreaInput
            name="comentario-lead"
            value={comment}
            onChange={handleComment}
            onBlur={() => handleBlur()}
            color={color as unknown as ColorNames}
          />
        ),
      },
    ];
  }, [leadData, comment]);

  return (
    <EditableSection<{}, GetEditLeadOnFormResponseQueryVariables>
      title="Detalhes"
      createQuery={undefined}
      editQuery={GetEditLeadOnFormResponseDocument}
      footerButtons={[]}
      createPayload={{}}
      editPayload={{ objectId: leadData._id! }}
      objectId={leadData._id}
      onSubmit={refetch}
      main
    >
      <DetailsSectionColumns columns={[leadInfo]} />
      <DetailsSectionColumns columns={[selectInfo, additonalInfo]} />
    </EditableSection>
  );
};

export default LeadSummary;
