/** @jsxImportSource @emotion/react */
import { MouseEvent, RefObject } from "react";
import { css } from "@emotion/react";
import { theme } from "../../theme";
import { TableColumn } from "../../hooks/useResizeableTable";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import cls from "classnames";
import Checkbox from "../Input/CheckboxInput";

export interface SortOption {
  id: string;
  sortKey: string;
  direction: 1 | -1;
}

export interface ResizableTableProps<T> {
  columns: TableColumn<T>[];
  tableData: T[];
  loading: boolean;
  tableRef: RefObject<HTMLTableElement>;
  columnWidths: Record<keyof T, number>;
  sort: SortOption;
  selectedRows: Record<string, boolean>;
  handleMouseDown: (e: React.MouseEvent, id: keyof T) => void;
  onSortClick: (id: string) => void;
  onCheckboxClick: (id: string) => void;
}

const getStyles = () => ({
  container: css`
    width: 100%;
  `,
  table: css`
    width: fit-content;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    .tr {
      display: flex;
    }

    tr,
    .tr {
      width: fit-content;
      height: 30px;
    }

    th,
    .th,
    td,
    .td {
      padding: 0.25rem;
      text-overflow: ellipsis;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
    }

    th,
    .th {
      padding: 2px 4px;
      padding-right: 30px;
      position: relative;
      font-weight: bold;
      height: 30px;
      border-bottom: 1px solid black;
      box-sizing: border-box;
      user-select: none;

      :hover .resizer {
        opacity: 1;
      }

      :last-child {
        .resizer {
          display: none;
        }
      }
    }

    td,
    .td {
      height: 30px;
      border-bottom: 1px solid ${theme.colors.Grey[40]};
      padding-right: 10px;
    }
  `,
  sortButton: css`
    position: absolute;
    top: 3px;
    bottom: 0;
    right: 5px;
    opacity: 0.3;
    svg {
      cursor: pointer;
    }

    &.selected {
      opacity: 1;
    }

    &.desc {
      transform: rotate(180deg);
    }
  `,
  resizer: css`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 4px;
    background: ${theme.colors.Green[60]};
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    opacity: 0;
  `,
});

const ResizableTable = <T extends { id: string }>({
  tableData,
  columns,
  loading,
  tableRef,
  columnWidths,
  sort,
  selectedRows,
  handleMouseDown,
  onSortClick,
  onCheckboxClick,
}: ResizableTableProps<T>) => {
  const styles = getStyles();

  const render = (row: T, col: TableColumn<T>) => {
    const value = row[col.id];
    if (value) {
      return col.cell ? col.cell(value as string) : (value as string);
    } else if (typeof col.emptyValue !== "undefined") {
      return col.emptyValue;
    } else {
      return "-";
    }
  };

  return (
    <div css={styles.container}>
      <table ref={tableRef} css={styles.table}>
        <thead>
          <tr>
            {columns.map((header, i) => {
              return (
                <th
                  key={header.id as string}
                  style={{
                    width: columnWidths[header.id],
                  }}
                >
                  {i === 0 ? (
                    <Checkbox
                      onChange={() => onCheckboxClick("all")}
                      checked={selectedRows.all}
                      label=""
                    />
                  ) : (
                    <>
                      {header.label.toUpperCase()}
                      {!header.disableSort ? (
                        <div
                          onClick={() => onSortClick(header.id as string)}
                          css={styles.sortButton}
                          className={cls({
                            selected: sort.id === header.id,
                            desc:
                              sort.id === header.id && sort.direction === -1,
                          })}
                        >
                          <Icon name={IconNames.ChevronDown} />
                        </div>
                      ) : null}
                    </>
                  )}
                  <div
                    onMouseDown={(e) => {
                      handleMouseDown(e, header.id);
                    }}
                    css={styles.resizer}
                    className="resizer"
                  />
                </th>
              );
            })}
          </tr>
        </thead>
        {!loading && (
          <tbody>
            {tableData.map((row, i) => (
              <tr key={i}>
                {columns.map((col, j) => {
                  return (
                    <td key={`${i}${j}`}>
                      {j === 0 ? (
                        <Checkbox
                          onChange={() => onCheckboxClick(row.id)}
                          checked={selectedRows[row.id]}
                          label=""
                        />
                      ) : (
                        render(row, col)
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default ResizableTable;
