/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorNames } from "../../../../theme";
import CardContainer from "../../../../components/CardList/components/CardContainer";
import CustomLink from "../../../../components/CustomLink";
import Typography from "../../../../components/Typography";
import StatusIconsList from "./components/StatusIconsList";
import SkeletonDashboardCard from "./components/SkeletonDashboardCard";
import useDashboardCounters from "../../hooks/useDashboardCounters";
import { TModelFilter } from "../../../../hooks/useModelFilter";
import {
  DocumentStages,
  UserRoles,
  LeadTypes,
} from "../../../../graphql/generated/types";

const cardHeaderHeight = 30;

const styles = {
  cardHeader: {
    display: "flex" as "flex",
    justifyContent: "space-between" as "space-between",
    alignItems: "center" as "center",
    height: cardHeaderHeight,
  },
  cardIcons: {
    display: "flex" as "flex",
    alignItems: "center" as "center",
    height: `calc(100% - ${cardHeaderHeight}px)`,
  },
};

export type TDashboardCard = {
  category: DocumentStages | UserRoles | LeadTypes;
  groupFilter: [string, string];
  buttonFilters: [string, string[]];
};

interface DashboardCardProps {
  color: ColorNames;
  cardData: TDashboardCard;
  filter: TModelFilter;
  location: string;
}

export type DashboardCardFC<T> = FC<{
  category: T;
}>;

const DashboardCard: FC<DashboardCardProps> = ({
  color,
  cardData,
  filter,
  location,
}) => {
  const [groupData, nestedCounters, loading] = useDashboardCounters({
    filter,
    groupFilter: cardData.groupFilter,
    buttonFilters: cardData.buttonFilters,
    location: location,
  });

  if (loading) return <SkeletonDashboardCard />;

  return (
    <CardContainer borderColor={color} disabled={!groupData.count}>
      <CustomLink to={groupData.redirectLocation}>
        <div css={styles.cardHeader}>
          <Typography
            variant="textLarge"
            align="left"
            uppercase
            component="p"
            weight="bold"
            css={{ cursor: "pointer" }}
          >
            {groupData.option.label}{" "}
            {groupData.count >= 0 && `[${groupData.count}]`}
          </Typography>
        </div>
        <div css={styles.cardIcons}>
          <StatusIconsList
            hoverColor={color}
            nestedCounters={nestedCounters}
            stage={groupData.option.value}
          />
        </div>
      </CustomLink>
    </CardContainer>
  );
};

export default DashboardCard;
