import { FC } from "react";
import Row from "../../../../components/Grid/Row";
import FormFieldSummary from "./FormFieldSummary";
import { FormFieldV2 } from "../../../../graphql/generated/types";
type ObjectFieldSummaryProps = {
  field: FormFieldV2;
};

const ObjectFieldSummary: FC<ObjectFieldSummaryProps> = ({
  field: { fields },
}) => {
  return (
    <Row>
      {fields!.map((field) => (
        <FormFieldSummary field={field} key={field.fieldPath} />
      ))}
    </Row>
  );
};

export default ObjectFieldSummary;
