/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import DetailsSectionColumns from "../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import FinancialResponsibleData from "./FinancialResponsibleData";
import { DetailsRowValueVariants } from "../../../../components/Details/components/Main/Grid/DetailsRowValue";
import { DetailsRowProps } from "../../../../components/Details/components/Main/Grid/DetailsRow";
import EditableSection from "../../../../components/EditableSection";
import {
  GetEditPaymentDataOnDocumentFormResponseDocument,
  GetEditPaymentDataOnDocumentFormResponseQueryVariables,
} from "../../../../graphql/generated/types";

enum PaymentMethod {
  debit = "débito",
  boleto = "boleto",
}

const PaymentInfo: FC = () => {
  const {
    documentData: { paymentData, _id: documentId, proposalResult },
    refetch,
  } = useDocumentDetails();

  const paymentInfo = useMemo<DetailsRowProps[]>(() => {
    const subventionLabels = [
      proposalResult?.amounts?.federalSubvention && "Subv. Federal",
      proposalResult?.amounts?.stateSubvention && "Subv. Estadual",
    ].filter(Boolean);

    const subventionText =
      subventionLabels.length > 0 ? ` + ${subventionLabels.join(" + ")}` : "";

    return [
      {
        label: "responsável",
        value: {
          text: paymentData?.responsible?.name!,
          Component: FinancialResponsibleData,
        },
        variant: DetailsRowValueVariants.Modal,
      },
      {
        label: "forma de pagamento",
        value: paymentData?.method && PaymentMethod[paymentData?.method!],
      },
      {
        label: "número de parcelas",
        value: `${paymentData?.installments}${subventionText}`,
      },
    ];
  }, [paymentData, proposalResult]);

  const accountInfo = useMemo(() => {
    return [
      {
        label: "banco",
        value: paymentData?.accountData?.bank,
      },
      {
        label: "agência",
        value: [
          paymentData?.accountData?.agency,
          paymentData?.accountData?.agencyDigit,
        ]
          .filter(Boolean)
          .join(" - "),
      },
      {
        label: "conta",
        value: [
          paymentData?.accountData?.account,
          paymentData?.accountData?.accountDigit,
        ]
          .filter(Boolean)
          .join(" - "),
      },
    ];
  }, [paymentData]);

  return (
    <EditableSection<{}, GetEditPaymentDataOnDocumentFormResponseQueryVariables>
      title="Dados de pagamento"
      noTitle
      main
      editQuery={GetEditPaymentDataOnDocumentFormResponseDocument}
      objectId={documentId}
      onSubmit={() => {
        refetch();
      }}
    >
      <DetailsSectionColumns columns={[paymentInfo, accountInfo]} />
    </EditableSection>
  );
};

export default PaymentInfo;
