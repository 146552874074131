import { useState } from "react";
import { TableColumn } from "./useResizeableTable";
import { TableActionsProps } from "../components/ResizableTable/components/TableActions";
import { SortOption } from "../components/ResizableTable";
import { json2csv } from "json-2-csv";

const useTableActions = <C, D extends { _id: string }>(
  allColumns: TableColumn<C>[],
  initialSort: SortOption,
  fetchCSVData: () => Promise<C[]>,
  data: D[]
): TableActionsProps<C> & {
  selectedRows: Record<string, boolean>;
  onCheckboxClick: (id: string) => void;
} => {
  const [activeView, setActiveView] = useState("card");
  const [selectedColumns, setSelectedColumns] = useState(allColumns);
  const [sort, setSort] = useState<SortOption>(initialSort);
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

  const tableColumnsOptions = allColumns
    .filter(({ id }) => id !== "checkbox")
    .map(({ id, label }) => ({
      value: id as string,
      label,
    }));

  const onTableSwitchViewClick = (id: string) => setActiveView(id);
  const onTableColumnsSelect = (selected: string[]) =>
    setSelectedColumns(
      allColumns.filter(
        ({ id }) => selected.includes(id as string) || id === "checkbox"
      )
    );
  const onResetColumnsClick = () => {
    setSelectedColumns(allColumns);
  };
  const onSortClick = (id: string) => {
    const col = allColumns.find((c) => c.id === id);
    if (col) {
      const direction = id !== sort.id ? 1 : ((sort.direction * -1) as 1 | -1);

      setSort({
        id: col.id as string,
        sortKey: col.sortKey || (col.id as string),
        direction,
      });
    }
  };

  const onCheckboxClick = (id: string) => {
    const checked = { ...selectedRows };
    if (id !== "all") {
      checked[id] = !selectedRows[id];
    } else {
      data.forEach(({ _id }) => (checked[_id] = !selectedRows.all));
    }

    const numberOfChecks = Object.keys(checked).reduce((prev, curr) => {
      if (curr !== "all" && checked[curr]) prev += 1;
      return prev;
    }, 0);

    checked.all = numberOfChecks === data.length;

    setSelectedRows(checked);
  };

  const downloadCSV = (data: any) => {
    const csv = json2csv(data);
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = "documents.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onDownloadCSVClick = async () => {
    const data = await fetchCSVData();
    downloadCSV(data);
  };

  return {
    activeView,
    selectedColumns,
    selectedRows,
    sort,
    tableColumnsOptions,
    onTableSwitchViewClick,
    onTableColumnsSelect,
    onResetColumnsClick,
    onSortClick,
    onDownloadCSVClick,
    onCheckboxClick,
  };
};

export default useTableActions;
