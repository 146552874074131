/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import Dashboard from "..";
import { Services } from "../../../graphql/generated/types";
import { ToLeadDetailsLocation, ToLeadFormLocation } from "../../../Locations";
import { leadCards } from "../components/Cards/assets/dashboardCards";
import LeadDashboardCard from "../components/Cards/components/LeadDashboardCard";
import { IconNames } from "../../../components/Icons/styles/iconNames";
import { useNavigate } from "react-router-dom";
import { useFlags } from "../../../hooks/useFlags";

const LeadsDashboard: FC = () => {
  const navigate = useNavigate();
  const { isFlagEnabled } = useFlags();

  const handleNewLead = () => {
    navigate(ToLeadFormLocation("new"));
  };

  const leadsActions = useMemo(() => {
    if (!isFlagEnabled("admin-only")) return;
    return [
      {
        text: "Novo Lead",
        iconName: IconNames.Add,
        onClick: handleNewLead,
      },
    ];
  }, [isFlagEnabled]);

  return (
    <Dashboard
      serviceName={Services.Leads}
      redirectLocation={ToLeadDetailsLocation}
      cards={leadCards}
      CardComponent={LeadDashboardCard}
      dashboardActions={leadsActions}
    />
  );
};

export default LeadsDashboard;
