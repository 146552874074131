/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import {
  ContactTags,
  Services,
} from "../../../../../../graphql/generated/types";
import UserSelect from "../../../../../../components/Details/components/Main/UserSelect";
import DetailsSectionColumn from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import ContactSelect from "../../../../../../components/Details/components/Main/ContactSelect";
import { useAuth } from "../../../../../../hooks/useAuth";
import { UserRoles } from "../../../../../../graphql/generated/types";
import Many2OneInput from "../../../../../../components/Input/SelectInputs/Many2OneInput";

const Selects = () => {
  const {
    documentData: {
      commercialResponsibleId,
      operationalResponsibleId,
      claimResponsibleId,
      originatorId,
      contactId,
      specialOperationId,
      crop,
    },
    handleSetCommercialResponsible,
    handleSetOperationalResponsible,
    handleSetClaimResponsible,
    handleSetOriginator,
    handleSetContact,
    handleSetSpecialOperation,
  } = useDocumentDetails();

  const auth = useAuth();

  const responsibles = useMemo(() => {
    return [
      {
        inputName: "Responsável Comercial",
        role: UserRoles.Admin,
        onSelect: handleSetCommercialResponsible,
        value: commercialResponsibleId || "",
      },
      {
        inputName: "Responsável Operacional",
        role: UserRoles.Admin,
        onSelect: handleSetOperationalResponsible,
        value: operationalResponsibleId || "",
      },
      {
        inputName: "Responsável pelo Sinistro",
        role: UserRoles.Admin,
        onSelect: handleSetClaimResponsible,
        value: claimResponsibleId || "",
      },
    ];
  }, [commercialResponsibleId, operationalResponsibleId, claimResponsibleId]);

  const responsiblesInfo = useMemo(() => {
    return responsibles.map((responsible) => ({
      label: responsible.inputName,
      value: (
        <UserSelect
          domain={{ role: responsible.role }}
          value={responsible.value}
          placeholder=""
          onSelect={(option) => responsible.onSelect(option?.value)}
          inputName={responsible.inputName}
          isClearable
        />
      ),
    }));
  }, [responsibles]);

  const contactInfo = useMemo(() => {
    const docContacts = [
      {
        inputName: "Parceiro",
        value: originatorId,
        onSelect: handleSetOriginator,
        disabled: auth.user.role !== UserRoles.Admin,
        domain: {
          tags: { $in: [ContactTags.Consultant, ContactTags.Organization] },
        },
        isClearable: true,
      },
      {
        inputName: "Contato",
        value: contactId,
        onSelect: handleSetContact,
        disabled: false,
        isClearable: false,
      },
    ];
    return docContacts.map((contact) => ({
      label: contact.inputName,
      value: (
        <ContactSelect
          value={contact.value}
          onSelect={(option) => contact.onSelect(option?.value)}
          inputName={contact.inputName}
          placeholder={contact.inputName}
          disabled={contact.disabled}
          domain={contact.domain}
          isClearable={contact.isClearable}
        />
      ),
    }));
  }, [contactId, originatorId, auth.user.role]);

  const specialOperationInfo = useMemo(
    () =>
      crop?.cultureId
        ? [
            {
              label: "Operação Especial",
              value: (
                <Many2OneInput
                  serviceName={Services.SpecialOperations}
                  optionDisplay="avatar"
                  domain={{ cultureId: crop?.cultureId }}
                  value={specialOperationId}
                  placeholder="Operação Especial"
                  onSelect={(option) =>
                    handleSetSpecialOperation(option?.value)
                  }
                  inputName="operationalResponsbibleId"
                  isClearable
                  disabled={auth.user.role !== UserRoles.Admin}
                />
              ),
            },
          ]
        : [],
    [specialOperationId, crop]
  );

  return (
    <DetailsSectionColumn
      xs={12}
      sm={6}
      rows={[...responsiblesInfo, ...contactInfo, ...specialOperationInfo]}
    />
  );
};

export default Selects;
