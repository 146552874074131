import { AuthContextInterface } from "../contexts/auth";
import { SimulationsObject } from "../models/Simulation";
import serviceFetch from "./fetch";

export const getSimulationsByRequest = async (
  auth: AuthContextInterface,
  request: string
): Promise<{ data: Array<SimulationsObject> }> =>
  serviceFetch(auth, `simulacoes/?${request}`, null, "get");

export const getSimulationById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: SimulationsObject }> =>
  serviceFetch(auth, `simulacoes/${id}`, null, "get");

export const approveSimulationById = async (
  auth: AuthContextInterface,
  id: string,
  resultIndex: string
): Promise<{ success: Boolean }> =>
  serviceFetch(auth, `simulacoes/${id}/approve/${resultIndex}`, null, "get");

export const retrySimulationById = async (
  id: string,
  resultIndex: string
): Promise<{ data: SimulationsObject }> =>
  serviceFetch(null, `simulacoes/${id}/retry/${resultIndex}`, null, "put", {});
