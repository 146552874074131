import { FC, useCallback } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import VariableFieldColumn from "../utils/VariableFieldColumn";
import BooleanInput from "../../../../components/Input/BooleanInput";

interface BooleanFieldProps {
  field: FormFieldV2;
}

const BooleanField: FC<BooleanFieldProps> = ({ field }) => {
  const { name, label, value, disabled, variant } = field;

  const { handleChange, finishEditing } = useFormField(field);

  const handleInputChange = useCallback(
    (e: { target: { value: boolean; name: string } }) => {
      handleChange(e.target.value);
      finishEditing();
    },
    [handleChange, finishEditing]
  );

  return (
    <VariableFieldColumn variant={variant}>
      <BooleanInput
        value={value}
        name={name}
        label={label}
        handleChange={handleInputChange}
        disabled={!!disabled}
      />
    </VariableFieldColumn>
  );
};

export default BooleanField;
