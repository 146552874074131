/** @jsxImportSource @emotion/react */

import moment from "moment";
import { FC, useMemo } from "react";
import { Note as TNote } from "../../../graphql/generated/types";
import { useCSSRulesWithTheme } from "../../../hooks/useCSSRulesWithTheme";
import Avatar from "../../Avatar";
import HR from "../../HorizontalLine";
import { IconSizes } from "../../Icons/styles/iconSizes";
import Typography from "../../Typography";
import { breakTextLines } from "../../../utils/formatString";

const textMarginLeft = 16;

const getCSSRules = () => ({
  noteHeader: {
    display: "flex",
  },
  title: {
    marginLeft: textMarginLeft,
    width: "100%",
    maxWidth: 300,
  },
  dateAndTime: {
    marginTop: -2,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  text: {
    marginLeft: IconSizes.ExtraLarge + textMarginLeft,
    "& > p": {
      margin: 0,
    },
  },
});

type NoteProps = TNote & {
  key: React.Key;
};

const Note: FC<NoteProps> = (note) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  moment.locale("pt-br");

  const date = useMemo(() => {
    return moment(note.createdAt).format("D [de] MMMM").toLocaleUpperCase();
  }, [note.createdAt]);

  const time = useMemo(() => {
    return moment(note.createdAt).format("HH:mm");
  }, [note.createdAt]);

  const author = useMemo(() => {
    if (note.authorId?.name === "Prisma Bot") {
      return "";
    }
    return note.authorId?.name;
  }, [note.authorId?.name]);

  const formattedText = useMemo(() => {
    const text = breakTextLines(note.text!).map((str, i) => (
      <p key={`note-paragraph-${i}`}>{str}</p>
    ));
    return (
      <Typography
        variant="textMedium"
        component="div"
        align="left"
        margin="sm"
        css={styles.text}
      >
        {text}
      </Typography>
    );
  }, [note.text]);

  return (
    <div css={{ marginTop: 20 }}>
      <div css={styles.noteHeader}>
        <Avatar name={author!} size={IconSizes.ExtraLarge} filled />
        <div css={styles.title}>
          <Typography variant="textLarge" component="p" align="left" uppercase>
            {note.authorId?.name}
          </Typography>
          <div css={styles.dateAndTime}>
            <Typography variant="textMedium" component="p" align="left">
              {date}
            </Typography>
            <Typography variant="textMedium" component="p" align="right">
              {time}
            </Typography>
          </div>
        </div>
      </div>
      {formattedText}
      <HR dashedLine />
    </div>
  );
};

export default Note;
