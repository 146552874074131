/** @jsxImportSource @emotion/react */
import { Outlet, useNavigate } from "react-router-dom";
import MainFrame from "../../components/MainFrame";
import { useUIStore } from "../../hooks/useUIStore";
import Layout from "../../components/Layout";
import { DocumentsLocation, ToDocumentDetailsLocation } from "../../Locations";
import {
  ListDocumentsDocument,
  ListDocumentsQuery,
  useCountDocumentsQuery,
  useListDocumentsQuery,
} from "../../graphql/generated/types";
import DocumentCard from "./DocumentCards/DocumentCard";
import useDocumentsFilter from "../../hooks/useDocumentsFilter";
import documentsTableColumns, {
  DocumentsTableData,
} from "./DocumentsTable/tableColumns";
import ListView from "../../components/ListView/ListView";
import useListView from "../../hooks/useListView";
import apollo from "../../graphql/apollo";
import { useEffect, useState } from "react";
import { formatISODate } from "../../utils/dateUtils";

const Documents = () => {
  const { toggleDrawerSize } = useUIStore();
  const navigate = useNavigate();
  const documentFilters = useDocumentsFilter();
  const [data, setData] = useState<ListDocumentsQuery["documents"]>([]);

  const mapTableData = (
    documents: ListDocumentsQuery["documents"]
  ): DocumentsTableData[] => {
    return documents.map((doc) => ({
      id: doc._id!,
      farmer: doc?.farmer?.name,
      stage: doc?.stage,
      status: doc?.status,
      insuranceTypeGroup: doc?.insuranceTypeGroup,
      detail: doc?.crop?.culture?.name || doc?.equipmentsDescription,
      area: doc?.crop?.area ? `${doc?.crop?.area} HA` : undefined,
      prize: doc?.premium ? String(doc?.premium) : undefined,
      city: doc?.properties?.[0]?.address?.city,
      uf: doc?.properties?.[0]?.address?.state,
      insuranceCompany: doc?.provider?.toUpperCase(),
      date: formatISODate(doc?.createdAt),
      partner: doc?.originator?.name?.toUpperCase(),
      ope: doc?.operationalResponsible?.name,
      com: doc?.commercialResponsible?.name,
      sin: doc?.claimResponsible?.name,
    }));
  };

  const fetchCSVData = async () => {
    const result = await apollo.client.query<ListDocumentsQuery>({
      query: ListDocumentsDocument,
      variables: {
        filter: documentFilters.composeQuery(),
      },
    });

    if (result.data.documents) {
      return mapTableData(result.data.documents);
    }

    return [];
  };

  const listViewProps = useListView(
    documentsTableColumns,
    { id: "date", sortKey: "createdAt", direction: -1 },
    fetchCSVData,
    data
  );

  const { sort, skip, limit } = listViewProps;

  const documentsQuery = useListDocumentsQuery({
    variables: {
      filter: documentFilters.composeQuery(),
      sort: { [sort.sortKey]: sort.direction },
      limit,
      skip,
    },
  });

  const countDocumentsQuery = useCountDocumentsQuery({
    variables: {
      filter: documentFilters.composeQuery(),
    },
  });

  useEffect(() => {
    if (documentsQuery.data?.documents) {
      setData(documentsQuery.data?.documents);
    }
  }, [documentsQuery]);

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(DocumentsLocation)}
        onToggle={toggleDrawerSize}
        filter={documentFilters}
      >
        <ListView<DocumentsTableData, ListDocumentsQuery["documents"][number]>
          {...listViewProps}
          total={countDocumentsQuery.data?.countDocuments || 0}
          CardComponent={DocumentCard}
          loading={documentsQuery.loading}
          redirectLocation={ToDocumentDetailsLocation}
          mapTableData={mapTableData}
        />
      </MainFrame>
    </Layout>
  );
};

export default Documents;
