import React, { FC, ReactNode, useCallback, useState } from "react";
import {
  ApproveSimulationResultDocument,
  DocumentStatus,
  GetEditObjectFormResponseDocument,
  InsuranceDocument,
  SendTransactionalEventDocument,
  Services,
  TransactionalEvents,
  RecedeDocumentStageDocument,
  SetCommercialResponsibleDocument,
  SetOperationalResponsibleDocument,
  ArchiveDocumentDocument,
  FinishSimulationDocument,
  DeleteSimulationResultDocument,
  RetrySimulationResultDocument,
  useRedoQuotationsMutation,
  useSetPaymentDoneMutation,
  useSelectResultsMutation,
  useSetQuotationCommentMutation,
  useArchiveProposalMutation,
  useGetShareTokenLazyQuery,
  useGetComplementaryDataFormResponseLazyQuery,
  useCancelPaymentMutation,
  useGetDocumentRejectionFormResponseLazyQuery,
  useGetNfeUploadFormResponseLazyQuery,
  useSetClaimResponsibleMutation,
  useRecommendSimulationResultMutation,
  useRemoveRecommendedSimulationResultMutation,
  useSendQuotationMutation,
  useSendProposalMutation,
  useSendPolicyMutation,
  useSetDocumentContactMutation,
  useCreatePaymentCopyMutation,
  useGetCreateKmlFileUploadFormResponseLazyQuery,
  InsuranceTypeGroupEnum,
  useSetDocumentSpecialOperationMutation,
  useSetOriginatorMutation,
} from "../graphql/generated/types";
import { useApolloClient } from "@apollo/client";
import { useErrorHandler } from "../hooks/useErrorHandler";
import { useAuth } from "../hooks/useAuth";
import { downloadFile } from "../services/models";
import { ModelNames } from "../models";
import { openFile } from "../helpers/openFile";
import useConfirmedAction from "../hooks/useConfirmedAction";
import {
  toComplementaryDataFormLocation,
  toKmlUploadFormLocation,
  toNfeUploadFormLocation,
} from "../Locations";
import PageTitle from "../components/PageTitle";

interface DocumentDetailsContextValue {
  refetch: () => Promise<void>;
  documentData: InsuranceDocument;
  handleSelectResults: (resultsIds: string[]) => Promise<void>;
  handleApproveResult: (resultId: string) => Promise<void>;
  copyQuotationFormUrl: () => Promise<void>;
  handleSendTransactionalEvent: (event: TransactionalEvents) => Promise<void>;
  handleChangeDocumentStage: (docStatus: DocumentStatus) => Promise<void>;
  handleSetCommercialResponsible: (contactId?: string) => Promise<void>;
  handleSetOperationalResponsible: (contactId?: string) => Promise<void>;
  handleSetClaimResponsible: (contactId?: string) => Promise<void>;
  handleSetOriginator: (contactId?: string) => Promise<void>;
  handleSetSpecialOperation: (specialOperationId?: string) => Promise<void>;
  handleSetContact: (contactId: string) => Promise<void>;
  handleArchive: () => Promise<void>;
  handleFinishQuotation: () => Promise<void>;
  redirectToQuotationFormUrl: () => Promise<void>;
  handleDeleteResult: (resultId: string) => Promise<void>;
  handleEditQuotationResult: (resultId: string) => void;
  editingQuotationResultId: string;
  handleRetrySimulationResult: (resultId: string) => Promise<void>;
  handleRedoQuotations: () => Promise<void>;
  handleSetPaymentDone: (paymentId: string) => Promise<void>;
  handleCancelPayment: (paymentId: string) => Promise<void>;
  handleSendQuotation: (silent?: boolean) => Promise<void>;
  handleSendProposal: (silent?: boolean) => Promise<void>;
  handleSendPolicy: (silent?: boolean) => Promise<void>;
  handleArchiveProposal: () => Promise<void>;
  handleCopyComplementaryFormLink: () => Promise<void>;
  handleCopyNfeUploadFormLink: () => Promise<void>;
  handleCopyKmlFileUploadFormLink: () => Promise<void>;
  // download functions
  handleKmlDownload: () => Promise<void>;
  handleDownloadComparisonPdf: () => Promise<void>;
  handleDownloadAllComparisonPdf: () => Promise<void>;
  handleDownloadProposalPdf: () => Promise<void>;
  handleRejectDocument: (documentId: string) => Promise<void>;
  handleRecommendSimulationResult: (resultId: string) => Promise<void>;
  handleRemoveRecommendedSimulationResult: () => Promise<void>;
  handleAddJustificationMessage: (message: string) => Promise<void>;
  handleCreatePaymentCopy: (paymentId: string) => Promise<void>;
}
export const DocumentDetailsContext =
  React.createContext<DocumentDetailsContextValue>({
    documentData: {
      _id: "",
      cropId: "",
      farmerId: "",
      contactId: "",
    },
    refetch: async () => {},
    handleSelectResults: async () => {},
    handleDownloadComparisonPdf: async () => {},
    handleDownloadAllComparisonPdf: async () => {},
    handleDownloadProposalPdf: async () => {},
    handleApproveResult: async () => {},
    copyQuotationFormUrl: async () => {},
    handleSendTransactionalEvent: async () => {},
    handleChangeDocumentStage: async () => {},
    handleSetCommercialResponsible: async () => {},
    handleSetOperationalResponsible: async () => {},
    handleSetClaimResponsible: async () => {},
    handleSetOriginator: async () => {},
    handleSetSpecialOperation: async () => {},
    handleArchive: async () => {},
    handleFinishQuotation: async () => {},
    redirectToQuotationFormUrl: async () => {},
    handleDeleteResult: async () => {},
    handleEditQuotationResult: async () => {},
    handleRetrySimulationResult: async () => {},
    editingQuotationResultId: "",
    handleKmlDownload: async () => {},
    handleRedoQuotations: async () => {},
    handleSetPaymentDone: async () => {},
    handleCancelPayment: async () => {},
    handleSendQuotation: async () => {},
    handleSendProposal: async () => {},
    handleSendPolicy: async () => {},
    handleArchiveProposal: async () => {},
    handleCopyComplementaryFormLink: async () => {},
    handleCopyNfeUploadFormLink: async () => {},
    handleRejectDocument: async () => {},
    handleRecommendSimulationResult: async () => {},
    handleRemoveRecommendedSimulationResult: async () => {},
    handleAddJustificationMessage: async () => {},
    handleSetContact: async () => {},
    handleCreatePaymentCopy: async () => {},
    handleCopyKmlFileUploadFormLink: async () => {},
  });

interface DocumentDetailsProviderProps {
  children: ReactNode;
  documentData: InsuranceDocument;
  refetch: () => Promise<any>;
}

const DocumentDetailsProvider: FC<DocumentDetailsProviderProps> = ({
  children,
  documentData,
  refetch,
}) => {
  const auth = useAuth();
  const apolloClient = useApolloClient();
  const { errorHandler, withErrorHandler } = useErrorHandler();

  const [selectResultsMutation] = useSelectResultsMutation();

  const handleSelectResults = useCallback(
    async (resultsIds: string[]) => {
      try {
        await selectResultsMutation({
          variables: {
            docId: documentData._id!,
            resultsIds,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível selecionar os resultados"), e);
      }
    },
    [documentData._id]
  );

  const [setQuotationCommentMutation] = useSetQuotationCommentMutation();
  const handleAddJustificationMessage = useCallback(
    async (message: string) => {
      try {
        await setQuotationCommentMutation({
          variables: {
            docId: documentData._id!,
            message,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível adicionar um comentário"), e);
      }
    },
    [documentData._id]
  );

  const handleDownloadFile = useCallback(
    async (fileKey: string) => {
      const file = await downloadFile(
        auth,
        ModelNames.DOCUMENTS,
        documentData._id!,
        fileKey
      );
      openFile(file);
    },
    [documentData._id, auth]
  );

  const handleDownloadComparisonPdf = useCallback(
    async () => handleDownloadFile("comparisonPdf"),
    [handleDownloadFile]
  );

  const handleDownloadAllComparisonPdf = useCallback(
    async () => handleDownloadFile("allComparisonPdf"),
    [handleDownloadFile]
  );

  const handleDownloadProposalPdf = useCallback(
    async () => handleDownloadFile("proposalFile"),
    [handleDownloadFile]
  );

  const handleKmlDownload = useCallback(
    async () => handleDownloadFile("kmlFile"),
    [handleDownloadFile]
  );

  const handleApproveResult = useCallback(
    async (resultId: string) => {
      try {
        await apolloClient.mutate({
          mutation: ApproveSimulationResultDocument,
          variables: {
            docId: documentData._id,
            resultId: resultId,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível aprovar a cotação"), e);
      }
    },
    [documentData._id]
  );

  const [getShareTokenQuery] = useGetShareTokenLazyQuery();
  const getShareToken = useCallback(async () => {
    try {
      const { data } = await getShareTokenQuery({
        variables: {
          service: Services.Documents,
          objectId: documentData._id!,
        },
      });
      return data!.getShareToken;
    } catch (e) {
      errorHandler(new Error("Não foi possível gerar o link do formulário"), e);
    }
  }, [documentData._id, getShareTokenQuery]);

  const generateQuotationFormUrl = useCallback(async () => {
    let formId;
    let urlFormId;
    if (documentData.insuranceTypeGroup === InsuranceTypeGroupEnum.Machinery) {
      formId = "equipmentProposal";
      urlFormId = "equipamentos";
    } else {
      formId = "cotacao";
      urlFormId = "cotacao";
    }
    try {
      const {
        data: { getEditObjectFormResponse: formResponseId },
      } = await apolloClient.query({
        query: GetEditObjectFormResponseDocument,
        variables: {
          service: Services.Documents,
          formId,
          objectId: documentData._id,
        },
      });
      const token = await getShareToken();
      const formLink = `/cotacoes/form/${urlFormId}/${formResponseId}?token=${token}`;
      return formLink;
    } catch (e) {
      errorHandler(new Error("Não foi possível gerar o link do formulário"), e);
    }
  }, [documentData._id, documentData.insuranceTypeGroup]);

  const [getComplementaryFormResponseQuery] =
    useGetComplementaryDataFormResponseLazyQuery();

  const generateComplementaryDataFormUrl = useCallback(async () => {
    try {
      const { data } = await getComplementaryFormResponseQuery({
        variables: {
          objectId: documentData._id!,
        },
      });
      const {
        getComplementaryDataFormResponse: { formResponseId },
      } = data!;
      const token = await getShareToken();
      const formLink = `${toComplementaryDataFormLocation(
        formResponseId,
        token!
      )}`;
      return formLink;
    } catch (e) {
      errorHandler(new Error("Não foi possível gerar o link do formulário"), e);
    }
  }, [documentData._id]);

  const [getNfeUploadFormResponseQuery] =
    useGetNfeUploadFormResponseLazyQuery();

  const generateNfeUploadFormUrl = useCallback(async () => {
    try {
      const { data } = await getNfeUploadFormResponseQuery({
        variables: {
          objectId: documentData._id!,
        },
      });
      const {
        getNfeUploadFormResponse: { formResponseId },
      } = data!;
      const token = await getShareToken();
      const formLink = `${toNfeUploadFormLocation(formResponseId, token!)}`;
      return formLink;
    } catch (e) {
      errorHandler(new Error("Não foi possível gerar o link do formulário"), e);
    }
  }, [documentData._id]);

  const handleCopyNfeUploadFormLink = useCallback(async () => {
    try {
      const formLink = await generateNfeUploadFormUrl();
      await copyLinkToClipboard(formLink!);
    } catch (e) {
      errorHandler(
        new Error("Não foi possível copiar o link do formulário"),
        e
      );
    }
  }, [documentData._id]);

  const [getCreateKmlFileUploadFormResponseQuery] =
    useGetCreateKmlFileUploadFormResponseLazyQuery();

  const generateKmlFileUploadFormUrl = useCallback(async () => {
    try {
      const { data } = await getCreateKmlFileUploadFormResponseQuery({
        variables: {
          documentId: documentData._id!,
        },
      });
      const {
        getCreateKmlFileUploadFormResponse: { formResponseId },
      } = data!;
      const token = await getShareToken();
      const formLink = `${toKmlUploadFormLocation(formResponseId, token!)}`;
      return formLink;
    } catch (e) {
      errorHandler(new Error("Não foi possível gerar o link do formulário"), e);
    }
  }, [documentData._id]);

  const handleCopyKmlFileUploadFormLink = useCallback(async () => {
    try {
      const formLink = await generateKmlFileUploadFormUrl();
      await copyLinkToClipboard(formLink!);
    } catch (e) {
      errorHandler(
        new Error("Não foi possível copiar o link do formulário"),
        e
      );
    }
  }, [documentData._id]);

  const redirectToQuotationFormUrl = useCallback(async () => {
    try {
      const formLink = await generateQuotationFormUrl();
      window.open(formLink, "_blank");
    } catch (e) {
      errorHandler(
        new Error("Não foi possível redirecionar para o formulário"),
        e
      );
    }
  }, [documentData._id]);

  const copyLinkToClipboard = useCallback(async (link: string) => {
    try {
      const { protocol, host } = window.location;
      const shareLink = `${protocol}//${host}${link}`;
      await navigator.clipboard.writeText(shareLink);
      alert(`Link de compartilhamento copiado!`);
    } catch (e) {
      errorHandler(
        new Error("Não foi possível copiar o link do formulário"),
        e
      );
    }
  }, []);

  const copyQuotationFormUrl = useCallback(async () => {
    try {
      const formLink = await generateQuotationFormUrl();
      await copyLinkToClipboard(formLink!);
    } catch (e) {
      errorHandler(
        new Error("Não foi possível copiar o link do formulário"),
        e
      );
    }
  }, [documentData._id, copyLinkToClipboard]);

  const handleCopyComplementaryFormLink = useCallback(async () => {
    try {
      const formLink = await generateComplementaryDataFormUrl();
      await copyLinkToClipboard(formLink!);
    } catch (e) {
      errorHandler(
        new Error("Não foi possível copiar o link do formulário"),
        e
      );
    }
  }, [documentData._id]);

  const handleSendTransactionalEvent = useCallback(
    async (event: TransactionalEvents) => {
      try {
        await apolloClient.mutate({
          mutation: SendTransactionalEventDocument,
          variables: {
            docId: documentData._id,
            event,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível enviar o email"), e);
      }
    },
    [documentData._id]
  );

  const handleChangeDocumentStage = useCallback(
    async (docStatus: DocumentStatus) => {
      try {
        await apolloClient.mutate({
          mutation: RecedeDocumentStageDocument,
          variables: {
            service: Services.Documents,
            docId: documentData._id,
            docStatus: docStatus,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(
          new Error("Não foi possível alterar o estágio do documento"),
          e
        );
      }
    },
    [documentData._id]
  );

  const handleSetCommercialResponsible = useCallback(
    async (contactId?: string) => {
      try {
        await apolloClient.mutate({
          mutation: SetCommercialResponsibleDocument,
          variables: {
            service: Services.Documents,
            docId: documentData._id,
            responsibleId: contactId,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(
          new Error("Não foi possível atribuir um responsável comercial"),
          e
        );
      }
    },
    [documentData._id]
  );

  const handleSetOperationalResponsible = useCallback(
    async (contactId?: string) => {
      try {
        await apolloClient.mutate({
          mutation: SetOperationalResponsibleDocument,
          variables: {
            service: Services.Documents,
            docId: documentData._id,
            responsibleId: contactId,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(
          new Error("Não foi possível atribuir um responsável operacional"),
          e
        );
      }
    },
    [documentData._id]
  );

  const [setClaimResponsibleMutation] = useSetClaimResponsibleMutation();
  const handleSetClaimResponsible = useCallback(
    async (contactId?: string) => {
      try {
        await setClaimResponsibleMutation({
          variables: {
            docId: documentData._id!,
            responsibleId: contactId,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(
          new Error("Não foi possível atribuir um responsável ao sinistro"),
          e
        );
      }
    },
    [documentData._id]
  );
  const setOriginator = useSetOriginatorMutation()[0];

  const handleSetOriginator = useCallback(
    withErrorHandler(async (originatorId?: string) => {
      await setOriginator({
        variables: { docId: documentData._id!, originatorId },
      });
      await refetch();
    }, "Erro ao atribuir parceiro"),
    [documentData._id, setOriginator, refetch]
  );

  const [setSpecialOperationMutation] =
    useSetDocumentSpecialOperationMutation();

  const handleSetSpecialOperation = useCallback(
    async (specialOperationId?: string) => {
      try {
        await setSpecialOperationMutation({
          variables: {
            docId: documentData._id!,
            specialOperationId,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(
          new Error("Não foi possível atribuir uma operação especial"),
          e
        );
      }
    },
    [documentData._id]
  );

  const [setContactMutation] = useSetDocumentContactMutation();

  const handleSetContact = useCallback(
    async (contactId: string) => {
      try {
        await setContactMutation({
          variables: {
            contactId,
            docId: documentData._id!,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível atribuir um parceiro"), e);
      }
    },
    [documentData._id]
  );

  const handleArchive = useCallback(async () => {
    try {
      await apolloClient.mutate({
        mutation: ArchiveDocumentDocument,
        variables: {
          docId: documentData._id,
        },
      });
      refetch();
    } catch (e) {
      errorHandler(new Error("Não foi possível arquivar o documento"), e);
    }
  }, []);

  const handleFinishQuotation = useCallback(async () => {
    try {
      await apolloClient.mutate({
        mutation: FinishSimulationDocument,
        variables: {
          service: Services.Documents,
          docId: documentData._id,
        },
      });
      refetch();
    } catch (e) {
      errorHandler(new Error("Não foi possível finalizar a cotação"), e);
    }
  }, [documentData._id]);

  const handleDeleteResult = useCallback(
    async (resultId: string) => {
      try {
        await apolloClient.mutate({
          mutation: DeleteSimulationResultDocument,
          variables: {
            resultId: resultId,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível deletar a cotação"), e);
      }
    },
    [documentData._id]
  );

  const [getDocumentRejectionFormResponse] =
    useGetDocumentRejectionFormResponseLazyQuery();

  const handleRejectDocument = useCallback(async () => {
    try {
      await getDocumentRejectionFormResponse({
        variables: {
          documentId: documentData._id!,
        },
      });
    } catch (e) {
      errorHandler(new Error("Não foi possível rejeitar o documento"), e);
    }
  }, [documentData._id]);

  const [editingQuotationResultId, setEditingQuotationResultId] =
    useState<string>("");

  const handleRetrySimulationResult = useCallback(
    async (resultId: string) => {
      try {
        await apolloClient.mutate({
          mutation: RetrySimulationResultDocument,
          variables: {
            resultId,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(
          new Error("Não foi possível tentar novamente a cotação"),
          e
        );
      }
    },
    [apolloClient]
  );

  const [redoQuotationsMutation] = useRedoQuotationsMutation();

  const handleRedoQuotations = useConfirmedAction(
    "Tem certeza? As cotações atuais serão removidas!",
    async () => {
      try {
        await redoQuotationsMutation({
          variables: {
            docId: documentData._id!,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível refazer as cotações"), e);
      }
    }
  );

  const [setPaymentDone] = useSetPaymentDoneMutation();

  const handleSetPaymentDone = useCallback(
    async (paymentId: string) => {
      try {
        await setPaymentDone({
          variables: {
            paymentId,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível dar baixa na parcela"), e);
      }
    },
    [documentData.payments]
  );

  const [cancelPaymentMutation] = useCancelPaymentMutation();

  const handleCancelPayment = useCallback(
    async (paymentId: string) => {
      try {
        await cancelPaymentMutation({
          variables: {
            paymentId,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível cancelar a parcela"), e);
      }
    },
    [documentData.payments]
  );

  const [sendQuotationMutation] = useSendQuotationMutation();

  const handleSendQuotation = useCallback(
    async (silent: boolean = false) => {
      try {
        await sendQuotationMutation({
          variables: {
            docId: documentData._id!,
            silent,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível enviar a cotação"), e);
      }
    },
    [documentData._id]
  );

  const [sendProposalMutation] = useSendProposalMutation();

  const handleSendProposal = useCallback(
    async (silent: boolean = false) => {
      try {
        await sendProposalMutation({
          variables: {
            docId: documentData._id!,
            silent,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível enviar a proposta"), e);
      }
    },
    [documentData._id]
  );

  const [sendPolicyMutation] = useSendPolicyMutation();

  const handleSendPolicy = useCallback(
    async (silent: boolean = false) => {
      try {
        await sendPolicyMutation({
          variables: {
            docId: documentData._id!,
            silent,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível enviar a apólice"), e);
      }
    },
    [documentData._id]
  );

  const [archiveProposalMutation] = useArchiveProposalMutation();

  const archiveProposal = useCallback(async () => {
    try {
      await archiveProposalMutation({
        variables: {
          docId: documentData._id!,
        },
      });
      refetch();
    } catch (e) {
      errorHandler(new Error("Não foi possível arquivar a proposta"), e);
    }
  }, [documentData._id]);

  const handleArchiveProposal = useConfirmedAction(
    "Tem certeza que deseja arquivar a proposta?",
    () => archiveProposal()
  );

  const [recommendSimulationResult] = useRecommendSimulationResultMutation();

  const handleRecommendSimulationResult = useCallback(
    async (resultId: string) => {
      try {
        await recommendSimulationResult({
          variables: {
            docId: documentData._id!,
            resultId,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Não foi possível adicionar a indicação."), e);
      }
    },
    [documentData._id]
  );

  const [removeRecommendedSimulationResult] =
    useRemoveRecommendedSimulationResultMutation();

  const handleRemoveRecommendedSimulationResult = useCallback(async () => {
    try {
      await removeRecommendedSimulationResult({
        variables: {
          docId: documentData._id!,
        },
      });
      refetch();
    } catch (e) {
      errorHandler(new Error("Não foi possível remover a indicação."), e);
    }
  }, [documentData._id]);

  const [createPaymentCopyMutation] = useCreatePaymentCopyMutation();

  const handleCreatePaymentCopy = useCallback(
    async (paymentId: string) => {
      try {
        await createPaymentCopyMutation({
          variables: {
            paymentId,
          },
        });
        refetch();
      } catch (e) {
        errorHandler(
          new Error("Não foi possível criar uma nova via da parcela."),
          e
        );
      }
    },
    [documentData.payments]
  );

  return (
    <>
      {documentData.farmer && documentData.crop && (
        <PageTitle
          title={`Documento - ${documentData.farmer.name} - ${documentData.crop.cultureName}`}
        />
      )}
      <DocumentDetailsContext.Provider
        value={{
          refetch,
          documentData,
          handleSetSpecialOperation,
          handleSelectResults,
          handleDownloadComparisonPdf,
          handleDownloadAllComparisonPdf,
          handleDownloadProposalPdf,
          handleKmlDownload,
          handleApproveResult,
          copyQuotationFormUrl,
          handleSendTransactionalEvent,
          handleChangeDocumentStage,
          handleSetCommercialResponsible,
          handleSetOperationalResponsible,
          handleSetClaimResponsible,
          handleSetOriginator,
          handleArchive,
          handleFinishQuotation,
          redirectToQuotationFormUrl,
          handleDeleteResult,
          editingQuotationResultId,
          handleEditQuotationResult: setEditingQuotationResultId,
          handleRetrySimulationResult,
          handleRedoQuotations,
          handleSetPaymentDone,
          handleArchiveProposal,
          handleCopyComplementaryFormLink,
          handleCopyNfeUploadFormLink,
          handleCopyKmlFileUploadFormLink,
          handleCancelPayment,
          handleRejectDocument,
          handleRecommendSimulationResult,
          handleRemoveRecommendedSimulationResult,
          handleAddJustificationMessage,
          handleSendQuotation,
          handleSendProposal,
          handleSendPolicy,
          handleSetContact,
          handleCreatePaymentCopy,
        }}
      >
        {children}
      </DocumentDetailsContext.Provider>
    </>
  );
};

export default DocumentDetailsProvider;
