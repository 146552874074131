import { FC, useMemo } from "react";
import {
  DocumentStages,
  DocumentStatus,
  GetCreateProposalOnDocumentFormResponseDocument,
  GetEditProposalOnDocumentFormResponseDocument,
} from "../../../../../graphql/generated/types";
import useProposalInfo from "./hooks/useProposalInfo";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import useDocumentOrder from "../../../../../hooks/useDocumentOrder";
import EditableSection from "../../../../../components/EditableSection";
import useFooterButtons from "./hooks/useFooterButtons";
import Typography from "../../../../../components/Typography";
import useDocumentColor from "../../../../../hooks/useDocumentColor";
import QuotationCard from "../../components/QuotationCard/index";
import { Row } from "react-grid-system";
import Column from "../../../../../components/Grid/Column";

const CurrentProposal: FC = () => {
  const {
    documentData: { proposalResult, _id, stage, status },
    refetch,
  } = useDocumentDetails();
  const proposalInfo = useProposalInfo();
  const currentStage = useDocumentOrder(stage!, status!);
  const policyStage = useDocumentOrder(DocumentStages.Policy);
  const pendingProposalStage = useDocumentOrder(
    DocumentStages.Proposal,
    DocumentStatus.Pending
  );
  const footerButtons = useFooterButtons();
  const documentColor = useDocumentColor(stage!);

  const editQuery = useMemo(
    () =>
      currentStage < policyStage
        ? GetEditProposalOnDocumentFormResponseDocument
        : undefined,
    [currentStage, policyStage]
  );

  const createQuery = useMemo(
    () =>
      currentStage === pendingProposalStage
        ? GetCreateProposalOnDocumentFormResponseDocument
        : undefined,
    [currentStage, pendingProposalStage]
  );

  return (
    <EditableSection
      title="Proposta"
      noTitle
      main
      createPayload={{ documentId: _id! }}
      createQuery={createQuery}
      editQuery={editQuery}
      objectId={proposalResult?._id}
      footerButtons={footerButtons}
      addText={"Adicionar Proposta"}
      useV2
      // addFlag="admin-only"
      // editFlag="admin-only"
      onSubmit={() => refetch()}
    >
      {!proposalInfo.length ? (
        <Typography variant="textMedium" uppercase color={documentColor}>
          Não há proposta adicionada
        </Typography>
      ) : (
        <>
          <DetailsSectionColumns columns={[proposalInfo]} />
          <Row>
            <Column xs={12} xl={6} xxl={4}>
              <QuotationCard result={proposalResult!} />
            </Column>
          </Row>
        </>
      )}
    </EditableSection>
  );
};

export default CurrentProposal;
