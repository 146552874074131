import { AuthContextInterface } from "../contexts/auth";
import { ProposalsObject } from "../models/Proposals";
import serviceFetch from "./fetch";

export const getProposalsByRequest = async (
  auth: AuthContextInterface,
  request: string
): Promise<{ data: Array<ProposalsObject> }> =>
  serviceFetch(auth, `propostas/?${request}`, null, "get");

export const getProposalById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: ProposalsObject }> =>
  serviceFetch(auth, `propostas/${id}`, null, "get");
