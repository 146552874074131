import TableActions, {
  TableActionsProps,
} from "../ResizableTable/components/TableActions";
import CardList from "../CardList";
import { CardFC } from "../CardList/components/Card";
import ResizableTable, { ResizableTableProps } from "../ResizableTable";
import Paginator, { PaginatorProps } from "../Paginator";

interface ListViewProps<C, D>
  extends TableActionsProps<C>,
    Omit<ResizableTableProps<C>, "columns" | "tableData">,
    PaginatorProps {
  data: D[];
  CardComponent: CardFC<D>;
  loading: boolean;
  redirectLocation: (id: string) => string;
  mapTableData: (data: D[]) => C[];
}

const ListView = <C extends { id: string }, D>({
  activeView,
  selectedColumns,
  tableColumnsOptions,
  sort,
  data,
  CardComponent,
  loading,
  tableRef,
  columnWidths,
  total,
  limit,
  currentPage,
  selectedRows,
  onPaginatorChange,
  handleMouseDown,
  redirectLocation,
  onTableSwitchViewClick,
  onTableColumnsSelect,
  onResetColumnsClick,
  onSortClick,
  onDownloadCSVClick,
  onCheckboxClick,
  mapTableData,
}: ListViewProps<C, D>) => {
  return (
    <>
      <TableActions
        activeView={activeView}
        selectedColumns={selectedColumns}
        tableColumnsOptions={tableColumnsOptions}
        sort={sort}
        onTableSwitchViewClick={onTableSwitchViewClick}
        onTableColumnsSelect={onTableColumnsSelect}
        onResetColumnsClick={onResetColumnsClick}
        onSortClick={onSortClick}
        onDownloadCSVClick={onDownloadCSVClick}
      />
      {activeView === "card" ? (
        <CardList
          data={data as D & { _id: string }[]}
          redirectLocation={redirectLocation}
          CardComponent={CardComponent}
          loading={loading}
        />
      ) : (
        <ResizableTable<C>
          columns={selectedColumns}
          loading={loading}
          tableRef={tableRef}
          columnWidths={columnWidths}
          sort={sort}
          selectedRows={selectedRows}
          handleMouseDown={handleMouseDown}
          tableData={mapTableData(data)}
          onSortClick={onSortClick}
          onCheckboxClick={onCheckboxClick}
        />
      )}
      <Paginator
        total={total}
        limit={limit}
        currentPage={currentPage}
        onPaginatorChange={onPaginatorChange}
      />
    </>
  );
};

export default ListView;
