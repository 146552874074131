import { IconNames } from "../components/Icons/styles/iconNames";
import {
  ClaimStatus,
  ContactTags,
  DocumentStages,
  DocumentStatus,
  InsuranceTypeGroupEnum,
  PropostaProviders,
  Services,
} from "../graphql/generated/types";
import { genericToFilter, responsibleFilter } from "./common";
import { TFilter } from "./types";

export const dashboardDocumentTypeFilters = (): TFilter[] => [
  insuranceTypeGroupFilter,
];

export const dashboardDocumentFilters = (): TFilter[] => [
  responsibleFilter,
  documentOriginatorFilter,
  documentProviderFilter,
  documentCultureFilter,
  documentStateFilter,
];

export const cardDocumentStatusFilters = () => documentStatusFilter.options;

export const getStatusIcon = (status: DocumentStatus) => {
  const statusIcon = documentStatusFilter.options.find(
    (option) => option.filter?.status === status
  )?.iconName;
  return statusIcon;
};

const documentFilters = (): TFilter[] => [
  documentStageFilter,
  responsibleFilter,
  documentOriginatorFilter,
  documentStatusFilter,
  documentProviderFilter,
  documentCultureFilter,
  documentStateFilter,
  insuranceTypeGroupFilter,
  claimStatusFilter,
];
export default documentFilters;

export const documentStageFilter: TFilter<DocumentStages> = {
  label: "Etapa",
  value: [],
  urlParam: "etapa",
  name: "stage",
  selectMultiValueDisplay: "all",
  options: [
    {
      name: DocumentStages.Simulation,
      value: "cotacao",
      label: "Cotações",
      filter: {
        stage: DocumentStages.Simulation,
      },
    },
    {
      name: DocumentStages.Proposal,
      value: "proposta",
      label: "Propostas",
      filter: {
        stage: DocumentStages.Proposal,
      },
    },
    {
      name: DocumentStages.Policy,
      value: "apolice",
      label: "Apólices",
      filter: {
        stage: DocumentStages.Policy,
      },
    },
  ],
};

export const documentStatusFilter: TFilter<DocumentStatus | "expired"> = {
  label: "Status",
  value: [],
  urlParam: "status",
  name: "status",
  selectOptionDisplay: "icon",
  selectMenuListDisplay: "buttons",
  selectMultiValueDisplay: "all",
  options: [
    {
      name: DocumentStatus.Incomplete,
      value: "incompletas",
      label: "Incompletas",
      filter: {
        status: DocumentStatus.Incomplete,
      },
      iconName: IconNames.Incomplete,
    },
    {
      name: DocumentStatus.Pending,
      value: "pendentes",
      label: "Pendentes",
      filter: {
        status: DocumentStatus.Pending,
      },
      iconName: IconNames.Pending,
    },
    {
      name: DocumentStatus.Done,
      value: "finalizadas",
      label: "Finalizadas",
      filter: {
        status: DocumentStatus.Done,
      },
      iconName: IconNames.Done,
    },
    {
      name: DocumentStatus.Sent,
      value: "enviadas",
      label: "Enviadas",
      filter: {
        status: DocumentStatus.Sent,
      },
      iconName: IconNames.Sent,
    },
    {
      name: "expired",
      value: "expiradas",
      label: "Expiradas",
      filter: {
        status: "expired",
      },
      iconName: IconNames.Expired,
    },
    {
      name: DocumentStatus.Effective,
      value: "vigentes",
      label: "Vigentes",
      filter: {
        status: DocumentStatus.Effective,
      },
      iconName: IconNames.Valid,
    },
    {
      name: DocumentStatus.NonEffective,
      value: "nao-vigentes",
      label: "Não Vigentes",
      filter: {
        status: DocumentStatus.NonEffective,
      },
      iconName: IconNames.Expired,
    },
    {
      name: DocumentStatus.UnderAnalysis,
      value: "em analise",
      label: "Em Análise",
      filter: {
        status: DocumentStatus.UnderAnalysis,
      },
      iconName: IconNames.Done,
    },
    {
      name: DocumentStatus.Rejected,
      value: "recusadas",
      label: "Recusadas",
      filter: {
        status: DocumentStatus.Rejected,
      },
      iconName: IconNames.Rejected,
    },
    {
      value: "arquivadas",
      label: "Arquivadas",
      filter: {
        archived: true,
      },
      iconName: IconNames.Archive,
    },
    {
      name: DocumentStatus.Cancelled,
      value: "canceladas",
      label: "Canceladas",
      filter: {
        status: DocumentStatus.Cancelled,
      },
      iconName: IconNames.Close,
    },
  ],
};

const documentOriginatorFilter: TFilter = {
  label: "Parceiro",
  value: [],
  urlParam: "parceiro",
  name: "originator",
  many2Many: {
    serviceName: Services.Contacts,
    domain: { tags: ContactTags.Consultant },
  },
  toFilter: genericToFilter("originatorId"),
  options: [
    {
      value: "nenhum",
      label: "Sem parceiro",
      filter: {
        originatorId: null,
      },
    },
  ],
};

const documentProviderFilter: TFilter = {
  label: "Seguradora",
  value: [],
  urlParam: "seguradora",
  name: "provider",
  options: Object.values(PropostaProviders).map((provider) => ({
    value: provider,
    label: provider.toUpperCase(),
    filter: {
      provider: provider,
    },
  })),
};

const documentCultureFilter: TFilter = {
  label: "Cultura",
  value: [],
  urlParam: "cultura",
  name: "culture",
  options: [],
};

const documentStateFilter: TFilter = {
  label: "Estado",
  value: [],
  urlParam: "estado",
  name: "state",
  selectMultiValueDisplay: "all",
  options: [
    { value: "AC", label: "Acre", filter: { state: "AC" } },
    { value: "AL", label: "Alagoas", filter: { state: "AL" } },
    { value: "AM", label: "Amazonas", filter: { state: "AM" } },
    { value: "AP", label: "Amapá", filter: { state: "AP" } },
    { value: "BA", label: "Bahia", filter: { state: "BA" } },
    { value: "CE", label: "Ceará", filter: { state: "CE" } },
    { value: "DF", label: "Distrito Federal", filter: { state: "DF" } },
    { value: "ES", label: "Espírito Santo", filter: { state: "ES" } },
    { value: "GO", label: "Goiás", filter: { state: "GO" } },
    { value: "MA", label: "Maranhão", filter: { state: "MA" } },
    { value: "MG", label: "Minas Gerais", filter: { state: "MG" } },
    { value: "MS", label: "Mato Grosso do Sul", filter: { state: "MS" } },
    { value: "MT", label: "Mato Grosso", filter: { state: "MT" } },
    { value: "PA", label: "Pará", filter: { state: "PA" } },
    { value: "PB", label: "Paraíba", filter: { state: "PB" } },
    { value: "PE", label: "Pernambuco", filter: { state: "PE" } },
    { value: "PI", label: "Piauí", filter: { state: "PI" } },
    { value: "PR", label: "Paraná", filter: { state: "PR" } },
    { value: "RJ", label: "Rio de Janeiro", filter: { state: "RJ" } },
    { value: "RN", label: "Rio Grande do Norte", filter: { state: "RN" } },
    { value: "RO", label: "Rondônia", filter: { state: "RO" } },
    { value: "RR", label: "Roraima", filter: { state: "RR" } },
    { value: "RS", label: "Rio Grande do Sul", filter: { state: "RS" } },
    { value: "SC", label: "Santa Catarina", filter: { state: "SC" } },
    { value: "SP", label: "São Paulo", filter: { state: "SP" } },
    { value: "SE", label: "Sergipe", filter: { state: "SE" } },
    { value: "TO", label: "Tocantins", filter: { state: "TO" } },
  ],
};

export const insuranceTypeGroupFilter: TFilter = {
  label: "Tipo de Seguro",
  value: [],
  urlParam: "seguro",
  name: "insurance",
  options: [
    {
      value: "agricola",
      label: "Agrícola",
      iconName: IconNames.Agriculture,
      filter: {
        insuranceTypeGroup: InsuranceTypeGroupEnum.Agricultural,
      },
    },
    {
      value: "maquinas",
      label: "Máquinas",
      iconName: IconNames.Machine,
      filter: {
        insuranceTypeGroup: InsuranceTypeGroupEnum.Machinery,
      },
    },
    {
      value: "benfeitorias",
      label: "Benfeitorias",
      iconName: IconNames.Benefectory,
      disabled: true,
      filter: {},
    },
    {
      value: "empresarial",
      label: "Empresarial",
      iconName: IconNames.Company,
      disabled: true,
      filter: {},
    },
    {
      value: "floresta",
      label: "Floresta",
      iconName: IconNames.Forest,
      disabled: true,
      filter: {},
    },
    {
      value: "pecuaria",
      label: "Pecuária",
      iconName: IconNames.Pecuary,
      disabled: true,
      filter: {},
    },
  ],
};

export const claimStatusFilter: TFilter<ClaimStatus | "noClaim" | "withClaim"> =
  {
    label: "Sinistros",
    value: [],
    urlParam: "sinistro",
    name: "claimStatus",
    selectOptionDisplay: "icon",
    selectMenuListDisplay: "buttons",
    selectMultiValueDisplay: "all",
    options: [
      {
        name: "noClaim",
        value: "sem-sinistro",
        label: "Sem Sinistro",
        filter: {
          claimStatus: "noClaim",
        },
        iconName: IconNames.Close,
      },
      {
        name: "withClaim",
        value: "com-sinistro",
        label: "Com Sinistro",
        filter: {
          claimStatus: "withClaim",
        },
        iconName: IconNames.Pending,
      },
      {
        name: ClaimStatus.Pending,
        value: "pendente",
        label: "Pendentes",
        filter: {
          claimStatus: ClaimStatus.Pending,
        },
        iconName: IconNames.Pending,
      },
      {
        name: ClaimStatus.Open,
        value: "aberto",
        label: "Abertos",
        filter: {
          claimStatus: ClaimStatus.Open,
        },
        iconName: IconNames.Claim,
      },
      {
        name: ClaimStatus.Approved,
        value: "deferido",
        label: "Deferidos",
        filter: {
          claimStatus: ClaimStatus.Approved,
        },
        iconName: IconNames.Done,
      },
      {
        name: ClaimStatus.Rejected,
        value: "indeferido",
        label: "Indeferidos",
        filter: {
          claimStatus: ClaimStatus.Rejected,
        },
        iconName: IconNames.Rejected,
      },
      {
        name: ClaimStatus.Contested,
        value: "em-contestacao",
        label: "Em Contestação",
        filter: {
          claimStatus: ClaimStatus.Contested,
        },
        iconName: IconNames.Close,
      },
      {
        name: ClaimStatus.Paid,
        value: "pago",
        label: "Pagos",
        filter: {
          claimStatus: ClaimStatus.Paid,
        },
        iconName: IconNames.Valid,
      },
    ],
  };
