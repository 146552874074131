import { IconNames } from "../components/Icons/styles/iconNames";
import { InsuranceTypeGroupEnum } from "../graphql/generated/types";

const useInsuranceIcon = (insuranceType: InsuranceTypeGroupEnum): IconNames => {
  switch (insuranceType) {
    case InsuranceTypeGroupEnum.Agricultural:
      return IconNames.Agriculture;
    case InsuranceTypeGroupEnum.Machinery:
      return IconNames.Machine;
  }
};

export default useInsuranceIcon;
