/** @jsxImportSource @emotion/react */

import { FC, useMemo, useState } from "react";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import Typography from "../../../../../../components/Typography";
import MessageInput, {
  messageInputHeight,
} from "../../../../../../components/Input/MessageInput";
import { theme } from "../../../../../../theme";
import OverflowMenu from "../../../../../../components/OverflowMenu";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import {
  DocumentStages,
  DocumentStatus,
} from "../../../../../../graphql/generated/types";
import { useFlags } from "../../../../../../hooks/useFlags";
import { breakTextLines } from "../../../../../../utils/formatString";

const styles = {
  container: {
    padding: 20,
    borderRadius: 15,
    backgroundColor: theme.colors.Grey[20],
    marginBottom: 24,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  message: {
    display: "flex",
    alignItems: "center",
    minHeight: messageInputHeight,
  },
};

const QuotationComment: FC = () => {
  const {
    documentData: { stage, status, justificationMessage },
    handleAddJustificationMessage,
  } = useDocumentDetails();

  const { isFlagEnabled } = useFlags();

  const [value, setNoteValue] = useState("");
  const [editing, setEditing] = useState(!justificationMessage);

  const editable = useMemo(() => {
    return (
      isFlagEnabled("admin-only") &&
      stage === DocumentStages.Simulation &&
      (status === DocumentStatus.Done || status === DocumentStatus.Sent)
    );
  }, [stage, status, isFlagEnabled]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteValue(e.target.value);
  };

  const handleSend = () => {
    handleAddJustificationMessage(value);
    setEditing(false);
  };

  const handleEdit = () => {
    setEditing(true);
    setNoteValue(justificationMessage || "");
  };

  const formattedMessage = useMemo(() => {
    const text = justificationMessage || "Nenhum comentário adicionado.";
    return breakTextLines(text).map((line, index) => (
      <Typography
        key={`textLine-${index}`}
        variant="textLarge"
        component="p"
        align="left"
      >
        {line}
      </Typography>
    ));
  }, [justificationMessage]);

  if (stage === DocumentStages.Simulation && status === DocumentStatus.Pending)
    return null;

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <Typography variant="textLarge" component="div" weight="bold">
          Comentário da equipe Prisma sobre os resultados:
        </Typography>
        {editable && !editing && (
          <OverflowMenu
            options={[
              {
                text: "Editar",
                iconName: IconNames.Edit,
                onClick: handleEdit,
              },
            ]}
          />
        )}
      </div>
      {editable && (editing || !justificationMessage) ? (
        <MessageInput
          value={value}
          placeholder="Escrever comentário"
          onChange={handleChange}
          onSend={handleSend}
        />
      ) : (
        formattedMessage
      )}
    </div>
  );
};

export default QuotationComment;
