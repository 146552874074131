/** @jsxImportSource @emotion/react */

import React, { CSSProperties, ReactElement, ReactNode } from "react";
import { Col as GridColumn, useScreenClass } from "react-grid-system";
import { Pull, Push, Offsets, ScreenClassMap } from "./Container";
import { css } from "@emotion/react";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { theme } from "../../theme";

interface ColumnProps extends ScreenClassMap<number> {
  width?: "auto" | number | string;
  offset?: Offsets;
  push?: Push;
  pull?: Pull;
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
  useYPaddings?: boolean;
}

const getCSSRules = ({ ...props }) => {
  if (!props.useYPaddings) return {};
  return {
    xs: {
      paddingTop: `${theme.gutters.xs / 2}px`,
      paddingBottom: `${theme.gutters.xs / 2}px`,
    },
    sm: {
      paddingTop: `${theme.gutters.sm / 2}px`,
      paddingBottom: `${theme.gutters.sm / 2}px`,
    },
    md: {
      paddingTop: `${theme.gutters.md / 2}px`,
      paddingBottom: `${theme.gutters.md / 2}px`,
    },
    lg: {
      paddingTop: `${theme.gutters.lg / 2}px`,
      paddingBottom: `${theme.gutters.lg / 2}px`,
    },
    xl: {
      paddingTop: `${theme.gutters.xl / 2}px`,
      paddingBottom: `${theme.gutters.xl / 2}px`,
    },
    xxl: {
      paddingTop: `${theme.gutters.xxl / 2}px`,
      paddingBottom: `${theme.gutters.xxl / 2}px`,
    },
  };
};

const Column = (props: ColumnProps): ReactElement => {
  const styles = useCSSRulesWithTheme(getCSSRules, props);
  const screenClass = useScreenClass();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { useYPaddings, ...rest } = props;
  return (
    <GridColumn
      {...rest} //@ts-ignore
      css={css(styles[screenClass])}
    />
  );
};

export default Column;
