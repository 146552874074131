import useModelFilter from "./useModelFilter";
import useUrlFilter from "./useUrlFilter";
import useModel from "./useModel";
import { UserObject } from "../models/User";
import { contactFilters } from "../filters/contacts";

const useContactsFilter = () => {
  const usersModel = useModel<UserObject>("users");
  const filters = useModelFilter(contactFilters, [
    async () =>
      usersModel.search({ role: "admin" }).then((res) => [
        "responsible",
        res.map((partner) => ({
          value: partner._id,
          label: partner.name!,
          filter: { responsibleId: partner._id },
        })),
      ]),
  ]);

  useUrlFilter(filters);

  return filters;
};

export default useContactsFilter;
