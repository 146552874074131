import Avatar from "../../../components/Avatar";
import { IconSizes } from "../../../components/Icons/styles/iconSizes";
import InsuranceCell from "./cells/InsuranceCell";
import StageCell from "./cells/StageCell";
import StatusCell from "./cells/StatusCell";
import {
  DocumentStages,
  DocumentStatus,
  InsuranceTypeGroupEnum,
} from "../../../graphql/generated/types";
import { TableColumn } from "../../../hooks/useResizeableTable";
import { NullPartial } from "../../../shared/types";
import { formatPrice } from "../../../utils/formatNumber";

type DocumentsTableColumns =
  | "id"
  | "farmer"
  | "stage"
  | "status"
  | "insuranceTypeGroup"
  | "detail"
  | "area"
  | "prize"
  | "city"
  | "uf"
  | "insuranceCompany"
  | "date"
  | "partner"
  | "ope"
  | "com"
  | "sin";

export interface DocumentsTableData
  extends NullPartial<Record<DocumentsTableColumns, string>> {
  id: string;
}

const documentsTableColumns: TableColumn<DocumentsTableData>[] = [
  { id: "farmer", label: "PRODUTOR", width: 200, sortKey: "farmer.name" },
  {
    id: "stage",
    label: "ETAPA",
    cell: (value) => <StageCell stage={value as DocumentStages} />,
    width: 120,
  },
  {
    id: "status",
    label: "STATUS",
    cell: (value) => <StatusCell status={value as DocumentStatus} />,
    width: 120,
  },
  {
    id: "insuranceTypeGroup",
    label: "SEGURO",
    cell: (value) => (
      <InsuranceCell insuranceType={value as InsuranceTypeGroupEnum} />
    ),
    width: 120,
  },
  { id: "detail", label: "DETALHE", sortKey: "culture.name" },
  { id: "area", label: "ÁREA", sortKey: "crop.area" },
  {
    id: "prize",
    label: "PRÊMIO",
    cell: (value) => formatPrice(Number(value)),
    emptyValue: "-",
    disableSort: true,
  },
  { id: "city", label: "CIDADE", sortKey: "property.address.city" },
  { id: "uf", label: "UF", width: 55, sortKey: "property.address.state" },
  { id: "insuranceCompany", label: "SEGURADORA", sortKey: "provider" },
  { id: "date", label: "DATA", sortKey: "createdAt" },
  { id: "partner", label: "PARCEIRO", sortKey: "originator.name" },
  {
    id: "ope",
    label: "OPE",
    cell: (value) => <Avatar name={value} size={IconSizes.Large} />,
    width: 70,
    disableSort: true,
  },
  {
    id: "com",
    label: "COM",
    cell: (value) => <Avatar name={value} size={IconSizes.Large} />,
    width: 70,
    disableSort: true,
  },
  {
    id: "sin",
    label: "SIN",
    cell: (value) => <Avatar name={value} size={IconSizes.Large} />,
    width: 70,
    disableSort: true,
  },
];

export default documentsTableColumns;
