/** @jsxImportSource @emotion/react */
import React from "react";
import TableSwitch from "./actions/TableSwitch";
import { css } from "@emotion/react";
import { TableColumn } from "../../../hooks/useResizeableTable";
import { SelectInputOptionInterface } from "../../Input/SelectInputs/SelectInput";
import { SortOption } from "..";
import MultiSelectInput from "../../Input/SelectInputs/MultiSelectInput";
import Button from "../../Button";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";

export interface TableActionsProps<T> {
  activeView: string;
  selectedColumns: TableColumn<T>[];
  tableColumnsOptions: SelectInputOptionInterface[];
  sort: SortOption;
  onTableSwitchViewClick: (active: string) => void;
  onTableColumnsSelect: (selected: string[]) => void;
  onResetColumnsClick: () => void;
  onSortClick: (id: string) => void;
  onDownloadCSVClick: () => Promise<void>;
}

const getStyles = () => ({
  container: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  `,
  right: css`
    display: flex;
    gap: 10px;
  `,
  resetButton: css`
    position: relative;
    > span {
      transform: scaleX(-1);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    svg {
      cursor: pointer;
    }
  `,
});

const TableActions = <T,>({
  activeView,
  selectedColumns,
  tableColumnsOptions,
  onTableSwitchViewClick,
  onTableColumnsSelect,
  onResetColumnsClick,
  onDownloadCSVClick,
}: TableActionsProps<T>) => {
  const styles = getStyles();

  return (
    <div css={styles.container}>
      <TableSwitch active={activeView} onClick={onTableSwitchViewClick} />
      <div css={styles.right}>
        <Button
          text="CSV"
          width={90}
          iconName={IconNames.Download}
          onClick={onDownloadCSVClick}
        />
        <MultiSelectInput
          onSelect={onTableColumnsSelect}
          value={selectedColumns.map(({ id }) => id)}
          inputName="columnsSelect"
          placeholder="Vizualisar Colunas"
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          options={tableColumnsOptions}
        />
        <Button
          customStyle={styles.resetButton}
          width={45}
          icon={<Icon name={IconNames.Redo} />}
          onClick={onResetColumnsClick}
        />
      </div>
    </div>
  );
};

export default TableActions;
