import { useMemo } from "react";
import { Culture } from "../../../../graphql/generated/types";
import Card, { CardFC } from "../../../../components/CardList/components/Card";
import { ColorNames } from "../../../../theme";
import { formatPrice } from "../../../../utils/formatNumber";

const CultureCard: CardFC<Culture> = ({ data, active, onClick }) => {
  const cardData = useMemo(() => {
    return {
      line1: data.name || "",
      line2: data.active ? "Ativo" : "Inativo",
      line3: data.currentSackValue
        ? `${formatPrice(data.currentSackValue)} / saca`
        : "",
      line4: "",
    };
  }, [data]);

  return (
    <Card
      cardData={cardData}
      color={ColorNames.GREEN}
      active={active}
      onClick={onClick}
    />
  );
};

export default CultureCard;
