import { SortOption } from "../components/ResizableTable";
import usePagination from "./usePagination";
import useResizeableTable, { TableColumn } from "./useResizeableTable";
import useTableActions from "./useTableActions";

const useListView = <C, D extends { _id: string }>(
  allColumns: TableColumn<C>[],
  initialSort: SortOption,
  fetchCSVData: () => Promise<C[]>,
  data: D[]
) => {
  const pagination = usePagination(9);
  const tableActions = useTableActions(
    [{ id: "checkbox", disableSort: true, width: 30 } as TableColumn<C>].concat(
      allColumns
    ),
    initialSort,
    fetchCSVData,
    data
  );
  const table = useResizeableTable(tableActions.selectedColumns);

  return {
    ...pagination,
    ...tableActions,
    ...table,
    data,
  };
};

export default useListView;
