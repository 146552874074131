import { FC, useMemo } from "react";
import { DetailsRowValueVariants } from "../../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import DetailsSectionColumns from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { DetailsRowProps } from "../../../../../../components/Details/components/Main/Grid/DetailsRow";
import EditableSection from "../../../../../../components/EditableSection";
import {
  Endorsement,
  EndorsementReason,
  EndorsementStatus,
  GetCreateEndorsementAnswerOnDocumentFormResponseDocument,
  GetEditEndorsementAnswerOnDocumentFormResponseDocument,
  GetEditEndorsementOnDocumentFormResponseDocument,
  Services,
} from "../../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import { useModalStore } from "../../../../../../components/Modal/context/ModalStore";
import { EndorsementStatusLabels } from "../../../../../../shared/endorsements/endorsementStatus";
import { EndorsementReasonLabels } from "../../../../../../shared/endorsements/endorsementReasons";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import { formatISODate } from "../../../../../../utils/dateUtils";

interface EndorsementProps {
  data: Endorsement;
}

const EndorsementComponent: FC<EndorsementProps> = ({ data: endorsement }) => {
  const { refetch } = useDocumentDetails();
  const { closeModal } = useModalStore();
  const endorsementInfo = useMemo(() => {
    const endorsementReason =
      endorsement.reason === EndorsementReason.Other
        ? "Outro: " + endorsement.otherReason
        : EndorsementReasonLabels[endorsement.reason];
    return [
      {
        label: "ID na Seguradora",
        value: endorsement.externalId,
      },
      {
        label: "Status",
        value: EndorsementStatusLabels[endorsement.status],
      },
      {
        label: "Motivo",
        value: endorsementReason,
      },
      {
        label: "Descrição",
        value: endorsement.description,
      },
      endorsement.consentFile?.fileKey && {
        label: "Arquivo de consentimento",
        variant: DetailsRowValueVariants.File,
        value: {
          file: endorsement.consentFile,
          objectId: endorsement?._id,
          fieldPath: "consentFile",
          serviceName: Services.Endorsements,
        },
      },
    ].filter(Boolean) as DetailsRowProps[];
  }, [endorsement]);

  const endorsementAnswer = useMemo(() => {
    return [
      endorsement.answerDate && {
        label: "Data da Resposta",
        value: formatISODate(endorsement.answerDate),
      },
      endorsement.rejectionReason && {
        label: "Motivo da Recusa",
        value: endorsement.rejectionReason,
      },
      endorsement.rejectionFile?.fileKey && {
        label: "Carta de Resposta",
        variant: DetailsRowValueVariants.File,
        value: {
          file: endorsement.rejectionFile,
          objectId: endorsement?._id,
          fieldPath: "rejectionFile",
          serviceName: Services.Endorsements,
        },
      },
      endorsement.endorsementFile?.fileKey && {
        label: "Endosso",
        variant: DetailsRowValueVariants.File,
        value: {
          file: endorsement.endorsementFile,
          objectId: endorsement?._id,
          fieldPath: "endorsementFile",
          serviceName: Services.Endorsements,
        },
      },
    ].filter(Boolean) as DetailsRowProps[];
  }, [endorsement]);

  const footerButtons = useMemo(() => {
    return [
      endorsement &&
        endorsement.status === EndorsementStatus.Pending && {
          text: "Finalizar Endosso",
          formQuery: {
            query: GetCreateEndorsementAnswerOnDocumentFormResponseDocument,
            variables: {
              objectId: endorsement?._id,
            },
          },
          iconName: IconNames.Valid,
        },
    ].filter(Boolean) as any;
  }, [endorsement]);

  const editQuery = useMemo(() => {
    return endorsement.status === EndorsementStatus.Pending
      ? GetEditEndorsementOnDocumentFormResponseDocument
      : GetEditEndorsementAnswerOnDocumentFormResponseDocument;
  }, [endorsement]);

  return (
    <EditableSection
      title={`${endorsement.externalId} - ${
        EndorsementReasonLabels[endorsement.reason]
      }`}
      main
      useV2
      editQuery={editQuery}
      objectId={endorsement._id}
      onSubmit={() => {
        refetch(), closeModal();
      }}
      footerButtons={footerButtons}
    >
      <DetailsSectionColumns columns={[endorsementInfo, endorsementAnswer]} />
    </EditableSection>
  );
};

export default EndorsementComponent;
