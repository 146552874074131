import { useCallback, useMemo, useState } from "react";
import { FormFieldV2 } from "../../../graphql/generated/types";
import useForm from "./useForm.v2";

const useFormField = (field: FormFieldV2) => {
  const {
    handleFieldUpdate,
    handleFileUpload,
    handleAddObjectListItem,
    handleDeleteObjectListItem,
    showFormErrors,
  } = useForm();
  const [editing, setEditing] = useState(false);
  const [edited, setEdited] = useState(false);

  const [loadsCount, setLoadsCount] = useState(0);
  const loading = useMemo(() => {
    return loadsCount > 0;
  }, [loadsCount]);

  const startEditing = useCallback(() => {
    setEditing(true);
  }, []);

  const finishEditing = useCallback(() => {
    setEditing(false);
    setEdited(true);
  }, []);

  const handleChange = useCallback(
    async (value: any) => {
      setLoadsCount((prev) => prev + 1);
      await handleFieldUpdate(field, value);
      setLoadsCount((prev) => prev - 1);
    },
    [field.fieldPath]
  );

  const error = useMemo(() => {
    if (!showFormErrors && (!edited || editing || loading)) return "";
    return field.error || "";
  }, [showFormErrors, editing, edited, loading, field.error]);

  const addListItem = useCallback(async () => {
    if (loading) return;
    setLoadsCount((prev) => prev + 1);
    await handleAddObjectListItem(field.fieldPath);
    setLoadsCount((prev) => prev - 1);
  }, [handleAddObjectListItem, field.fieldPath, loading]);

  const deleteListItem = useCallback(async () => {
    if (loading) return;
    setLoadsCount((prev) => prev + 1);
    await handleDeleteObjectListItem(field.fieldPath);
    setLoadsCount((prev) => prev - 1);
  }, [handleDeleteObjectListItem, field.fieldPath, loading]);

  const handleFileChange = useCallback(
    async (file: File) => {
      if (loading) return;
      setLoadsCount((prev) => prev + 1);
      await handleFileUpload(field, file);
      setLoadsCount((prev) => prev - 1);
      finishEditing();
    },
    [handleFileUpload, field.fieldPath, loading]
  );

  return {
    handleChange,
    editing,
    startEditing,
    finishEditing,
    error,
    addListItem,
    deleteListItem,
    handleFileChange,
    loading,
  };
};

export default useFormField;
