/** @jsxImportSource @emotion/react */

import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { useUIStore } from "../../hooks/useUIStore";
import { ToDirectSimulationLocation } from "../../Locations";
import { useGetContactBySlugLazyQuery } from "../../graphql/generated/types";

const styles = {
  container: {
    height: "100vh",
    margin: "auto",
  },
};

const SharedForm: FC = () => {
  const { setIsLoading } = useUIStore();
  const { slug } = useParams();
  const navigate = useNavigate();

  const [getContactBySlug] = useGetContactBySlugLazyQuery();

  const setOriginatorData = async () => {
    setIsLoading(true);
    const originatorResponse = await getContactBySlug({
      variables: {
        slug: slug as string,
      },
    });
    setIsLoading(false);
    const originatorId = originatorResponse.data?.contactBySlug?._id;
    if (originatorId) {
      localStorage.setItem("originador", originatorId);
      navigate(ToDirectSimulationLocation("new", "simulacao"));
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    setOriginatorData();
  }, []);

  return (
    <div css={styles.container}>
      <Loading size="large" />
    </div>
  );
};

export default SharedForm;
