/** @jsxImportSource @emotion/react */

import React, { FC, Fragment, useMemo } from "react";
import {
  Services,
  Task as TTask,
  useModelTasksQuery,
} from "../../../../graphql/generated/types";
import { getDaysInterval } from "../../../../utils/getDaysInterval";
import Typography from "../../../../components/Typography";
import HR from "../../../../components/HorizontalLine";
import { ColorNames } from "../../../../theme";
import useGroupedChunk from "../../../../hooks/useGroupedChunk";
import { useNavigate } from "react-router-dom";
import Notification from "../../../../components/Notification";
import TasksContainer from "./components/Container";

interface TasksProps {
  serviceName: Services;
  redirectLocation: (docId: string) => string;
}

const taskGroups: {
  label: string;
  filter: (task: TTask) => boolean;
}[] = [
  {
    label: "Expiradas",
    filter: (task: TTask) =>
      getDaysInterval(task?.dateDue, "task") < 0 &&
      getDaysInterval(task?.dateDue, "task") >= -30,
  },
  {
    label: "Próximas ao vencimento",
    filter: (task: TTask) =>
      getDaysInterval(task?.dateDue, "task") > 0 &&
      getDaysInterval(task?.dateDue, "task") <= 3,
  },
  {
    label: "Hoje",
    filter: (task: TTask) => getDaysInterval(task?.dateDue, "task") === 0,
  },
  {
    label: "Futuras",
    filter: (task: TTask) => getDaysInterval(task?.dateDue, "task") > 3,
  },
  {
    label: "Expiradas há mais de 30 dias",
    filter: (task: TTask) => getDaysInterval(task?.dateDue, "task") < -30,
  },
];

const Tasks: FC<TasksProps> = ({ serviceName, redirectLocation }) => {
  const navigate = useNavigate();

  const { data, loading } = useModelTasksQuery({
    variables: {
      serviceName,
    },
  });

  const { groupedChuncks, totalCount } = useGroupedChunk<TTask>(
    data?.modelTasks || [],
    taskGroups
  );

  const tasksByGroup = useMemo(
    () =>
      groupedChuncks.map((group) => ({
        label: group.label,
        tasks: group.list.map((task, i) => (
          <Notification
            key={`${group.label}-${i}`}
            task={task}
            redirectLocation={redirectLocation}
          />
        )),
      })),
    [groupedChuncks, navigate, redirectLocation]
  );

  return (
    <TasksContainer count={totalCount} loading={loading}>
      {tasksByGroup.map((group, i) => (
        <Fragment key={`${group.label}-${i}`}>
          {group.tasks.length > 0 && (
            <>
              <Typography
                variant="textMedium"
                color="Green"
                uppercase
                align="left"
                component="p"
              >
                {group.label}
              </Typography>
              <HR color={ColorNames.GREEN} spacingSize="small" dashedLine />
              {group.tasks}
            </>
          )}
        </Fragment>
      ))}
    </TasksContainer>
  );
};

export default Tasks;
