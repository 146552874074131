import { LeadTypes, UserRoles } from "../../../../../graphql/generated/types";
import { TDashboardCard } from "../DashboardCard";

export const contactCards: TDashboardCard[] = [
  {
    category: UserRoles.Consultant,
    groupFilter: ["tipo", "consultor"],
    buttonFilters: ["status", ["cadastrado", "aprovado", "ativo", "recusado"]],
  },
];

export const leadCards: TDashboardCard[] = [
  {
    category: LeadTypes.Customer,
    groupFilter: ["tipo", "cliente"],
    buttonFilters: [
      "status",
      ["novo", "diagnostico", "apresentacao", "negociacao"],
    ],
  },
  {
    category: LeadTypes.Partner,
    groupFilter: ["tipo", "parceiro"],
    buttonFilters: [
      "status",
      ["novo", "diagnostico", "apresentacao", "negociacao"],
    ],
  },
];
