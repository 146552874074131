import { IconNames } from "../components/Icons/styles/iconNames";
import { UserRoles, UserStatus } from "../graphql/generated/types";
import { TFilter } from "./types";

export const getStatusIcon = (status: UserStatus) => {
  const statusIcon = userFilters()
    .find((filter) => filter.label === "Status")
    ?.options.find((option) => option.filter?.status === status)?.iconName;
  return statusIcon;
};

const userFilters = (): TFilter[] => [
  {
    label: "Papel",
    value: [],
    urlParam: "tipo",
    name: "tipo",
    selectMultiValueDisplay: "all",
    options: [
      {
        value: "administrador",
        label: "Administradores",
        filter: {
          role: UserRoles.Admin,
        },
      },
      {
        value: "consultor",
        label: "Consultores",
        filter: {
          role: UserRoles.Consultant,
        },
      },
    ],
  },
  {
    label: "Status",
    value: [],
    urlParam: "status",
    name: "status",
    selectOptionDisplay: "icon",
    selectMenuListDisplay: "buttons",
    selectMultiValueDisplay: "all",
    options: [
      {
        value: "cadastrado",
        label: "Cadastrados",
        filter: {
          status: UserStatus.Registered,
        },
        iconName: IconNames.Pending,
      },
      {
        value: "aprovado",
        label: "Aprovados",
        filter: {
          status: UserStatus.Approved,
        },
        iconName: IconNames.Done,
      },
      {
        value: "ativo",
        label: "Ativos",
        filter: {
          status: UserStatus.Active,
        },
        iconName: IconNames.Valid,
      },
      {
        value: "recusado",
        label: "Recusados",
        filter: {
          status: UserStatus.Rejected,
        },
        iconName: IconNames.Rejected,
      },
    ],
  },
  {
    label: "Estado",
    value: [],
    urlParam: "estado",
    name: "state",
    selectMultiValueDisplay: "all",
    options: [
      { value: "AC", label: "Acre", filter: { state: "AC" } },
      { value: "AL", label: "Alagoas", filter: { state: "AL" } },
      { value: "AM", label: "Amazonas", filter: { state: "AM" } },
      { value: "AP", label: "Amapá", filter: { state: "AP" } },
      { value: "BA", label: "Bahia", filter: { state: "BA" } },
      { value: "CE", label: "Ceará", filter: { state: "CE" } },
      { value: "DF", label: "Distrito Federal", filter: { state: "DF" } },
      { value: "ES", label: "Espírito Santo", filter: { state: "ES" } },
      { value: "GO", label: "Goiás", filter: { state: "GO" } },
      { value: "MA", label: "Maranhão", filter: { state: "MA" } },
      { value: "MG", label: "Minas Gerais", filter: { state: "MG" } },
      { value: "MS", label: "Mato Grosso do Sul", filter: { state: "MS" } },
      { value: "MT", label: "Mato Grosso", filter: { state: "MT" } },
      { value: "PA", label: "Pará", filter: { state: "PA" } },
      { value: "PB", label: "Paraíba", filter: { state: "PB" } },
      { value: "PE", label: "Pernambuco", filter: { state: "PE" } },
      { value: "PI", label: "Piauí", filter: { state: "PI" } },
      { value: "PR", label: "Paraná", filter: { state: "PR" } },
      { value: "RJ", label: "Rio de Janeiro", filter: { state: "RJ" } },
      { value: "RN", label: "Rio Grande do Norte", filter: { state: "RN" } },
      { value: "RO", label: "Rondônia", filter: { state: "RO" } },
      { value: "RR", label: "Roraima", filter: { state: "RR" } },
      { value: "RS", label: "Rio Grande do Sul", filter: { state: "RS" } },
      { value: "SC", label: "Santa Catarina", filter: { state: "SC" } },
      { value: "SP", label: "São Paulo", filter: { state: "SP" } },
      { value: "SE", label: "Sergipe", filter: { state: "SE" } },
      { value: "TO", label: "Tocantins", filter: { state: "TO" } },
    ],
  },
];

export default userFilters;
