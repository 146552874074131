import { IconNames } from "../components/Icons/styles/iconNames";
import { DocumentStatus } from "../graphql/generated/types";

const useStatusIcon = (status: DocumentStatus): IconNames => {
  switch (status) {
    case DocumentStatus.Pending:
      return IconNames.Pending;
    case DocumentStatus.Incomplete:
      return IconNames.Incomplete;
    case DocumentStatus.Done:
      return IconNames.Done;
    case DocumentStatus.Sent:
      return IconNames.Sent;
    case DocumentStatus.Rejected:
      return IconNames.Rejected;
    case DocumentStatus.Effective:
      return IconNames.Valid;
    case DocumentStatus.UnderAnalysis:
      return IconNames.Proposal;

    case DocumentStatus.Cancelled:
    case DocumentStatus.NonEffective:
      return IconNames.Close;
  }
};

export default useStatusIcon;
