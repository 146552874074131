/** @jsxImportSource @emotion/react */
import { FC } from "react";
import Column from "../../../../components/Grid/Column";
import { CustomTheme, theme } from "../../../../theme";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import { BaseFontSize } from "../../../../components/Typography";

type DefaultFieldSummaryProps = {
  value: string;
};

const getStyles: CSSRulesResolver = ({ colors }: CustomTheme) => ({
  formField: {
    color: colors.Black[100],
    width: "100%",
    height: 45,
    borderRadius: 5,
    border: "0.2px solid rgba(0, 0, 0, 0.2)",
    paddingLeft: 12,
    lineHeight: "45px",
    textAlign: "start",
    backgroundColor: colors.White[100],
    boxSizing: "border-box",
    boxShadow: theme.boxShadow.default,
    overflow: "hidden",
    fontSize: BaseFontSize,
  },
});

const DefaultFieldSummary: FC<DefaultFieldSummaryProps> = ({ value }) => {
  const styles = useCSSRulesWithTheme(getStyles);
  return (
    <Column xs={12}>
      <div css={styles.formField}>{value}</div>
    </Column>
  );
};

export default DefaultFieldSummary;
